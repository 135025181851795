import { css } from "emotion";

export const TrekMetaInfo = css`
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 1%;
  text-align: center;

  @media (max-width: 600px) {
    font-size: 14px;
    margin-top: 4%;
  }
`;


export const MainContainer = css`
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0,0,0,0.3));
  ${'' /* border: 1px solid red; */}
`;