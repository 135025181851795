import React, { Component } from "react";
import {
  InfoSectionHeader,
  NavigationBarButtomContainer
} from "../../common/styledComponents";
import { Button, Affix, Row, Col } from "antd";
import { NavigationContainer, BarHeader, BarMeta } from "./NavigationBar.css";
import RegistrationForm from "./RegistrationForm";
import { saveFormDetails, openNotificationWithIcon } from "../../common/utils";
import { isMobile } from "../../common/utils";


class NavigationBar extends Component {
  mobile= isMobile();
  state = {
    isFixed: false,
    openModal: false,
    isFormSubmitting: false
  };
  onAffixedStateChanged = isFixed => {
    this.setState({
      isFixed
    });

    // CAUTION: Wrong way to do, find a moew declarative way
    const header = document.querySelector("#main-header");
    if (header) {
      if (isFixed) {
        header.style.display = "none";
      } else {
        header.style.display = "block";
      }
    }
  };

  toggleOpenModal = () => {
    this.setState(state => ({
      openModal: !state.openModal
    }));
  };
  onSubmitCreateTrekForm = values => {
    // console.log("Outer values recieved: ", values);
    this.setState({
      isFormSubmitting: true
    });
    values = JSON.parse(
      JSON.stringify({
        ...values,
        name: this.props.name,
        type: "REGISTER FOR TREK"
      })
    );
    saveFormDetails(values)
      .then(response => {
        // console.log("val"+ values)
        // console.log(response);
        this.setState({
          isFormSubmitting: false
        });
        openNotificationWithIcon("success", {
          message: "Request recieved",
          description:
            "Your request has been recieved. A service agent from our side will be calling you soon!"
        });
        this.toggleOpenModal();
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isFormSubmitting: false
        });
        openNotificationWithIcon("error", {
          message: "Oops!",
          description:
            "Looks like an error on our side. Please contact himalayanweekenders@gmail.com!"
        });
        this.toggleOpenModal();
      });
  };
  getItemTypeText = () => {
    const { type } = this.props;
    if (type === "TREK") {
      return "Register for this trek";
    } else if (type === "TRIP") {
      return "Register for this trip";
    } else if (type === "ADVENTURE_TRIP") {
      return "Register";
    }
    return "Register";
  };
  render() {
    const { type, name, price, duration } = this.props;
    const { isFixed, isFormSubmitting } = this.state;
    return (
      <Affix onChange={this.onAffixedStateChanged}>
        <RegistrationForm
          openModal={this.state.openModal}
          toggleOpenModal={this.toggleOpenModal}
          onSubmit={this.onSubmitCreateTrekForm}
          loading={isFormSubmitting}
        />
        <div
          className={NavigationContainer}
          style={
            isFixed
              ? {
                  boxShadow: "0 2px 2px 0 rgba(0,0,0,0.06)",
                  border: "solid 1px #d5d5d5"
                }
              : {}
          }
        >
          <Row>
            <Col xs={24} md={18} sm={24} >
              <InfoSectionHeader className={BarHeader} style={this.mobile? {textAlign:'center'}:null}>
                {name}
                <br />
                <span className={BarMeta} >
                  {!!price ? `₹${price} + 5% GST` : null}
                  {type !== "ADVENTURE_TRIP" ? `, ${duration}` : null}
                </span>
              </InfoSectionHeader>
            </Col>
            <Col xs={24} md={6} sm={24}>
              <NavigationBarButtomContainer>
                <Button
                  type="primary"
                  size="large"
                  onClick={this.toggleOpenModal}
                >
                  {this.getItemTypeText()}
                </Button>
              </NavigationBarButtomContainer>
            </Col>
          </Row>

          {/* NOTE: Use this class for selected state: ${SelectedNavigationItem} */}
          {/* <Row type="flex" justify="space-between">
            {!isMobile() &&
              items.map(i => <Col className={`${NavigationItem}`}>{i}</Col>)}
          </Row> */}
        </div>
      </Affix>
    );
  }
}

export default NavigationBar;
