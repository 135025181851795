import React, { Component } from "react";
import {
  InfoSection,
  InfoSectionHeader,
  InfoContent
} from "../../common/styledComponents";
import { Row, Col } from "antd";
import BREAKFAST from "../../../images/breakfast.svg";
import HANGOUT from "../../../images/hangout.svg";
import MONEY from "../../../images/money.svg";
import TRANSPORT from "../../../images/transport.svg";
// import GROUP_ACTIVITIES from "../../../images/group-activities.svg";
// import BONFIRE from "../../../images/bonfire.svg";
// import CAMPAIGN from "../../../images/campaign.svg";
import { css } from "emotion";

const SectionContainer = css`
  font-size: 20px;
  line-height: 1.8;
  text-align: justify;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const ItemsContainer = css`
  padding: 3% 20%;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const ItemBox = css`
  padding: 4% 0;

  @media (max-width: 600px) {
    padding: 6% 0;
  }
`;

const ItemName = css`
  font-size: 20px;
  vertical-align: middle;
  @media (max-width: 600px) {
    font-size: 11px;
  }
`;

const OverviewItem = props => (
  <div className={ItemBox}>
    <img style={{ marginRight: 10 }} src={props.src} alt="Breakfast" />
    <span className={ItemName}>{props.text}</span>
  </div>
);

class Overview extends Component {
  render() {
    const { content, price, pickupPoint, type } = this.props;
    return (
      <InfoSection>
        <InfoSectionHeader>Overview</InfoSectionHeader>
        <InfoContent className={SectionContainer}>{content}</InfoContent>
        {type !== "ADVENTURE_TRIP" ? (
          <Row className={ItemsContainer}>
            <Col span={12}>
              <OverviewItem src={BREAKFAST} text="All meals" />
            </Col>
            <Col span={12}>
              <OverviewItem
                src="https://res.cloudinary.com/hw/image/upload/c_limit,f_auto,w_24/v1539472665/Camping.png"
                text="Permit and camping fees"
              />
            </Col>
            <Col span={12}>
              <OverviewItem src={HANGOUT} text="Accommodation" />
            </Col>
            <Col span={12}>
              <OverviewItem
                src="https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_24/v1539472665/Bonfire.png"
                text="Bonfire and music"
              />
            </Col>
            <Col span={12}>
              <OverviewItem
                src="https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_24/v1539472924/Guide_and_support_team-01.png"
                text="Guide and support team"
              />
            </Col>
            <Col span={12}>
              <OverviewItem src={TRANSPORT} text={"Pickup: " + pickupPoint} />
            </Col>
            <Col span={12}>
              <OverviewItem src={MONEY} text={"₹ " + price} />
            </Col>
            <Col span={12}>
              <OverviewItem
                src="https://res.cloudinary.com/hw/image/upload/c_scale,f_auto,w_24/v1539472665/Group_Games.png"
                text="Group activities"
              />
            </Col>
          </Row>
        ) : null}
      </InfoSection>
    );
  }
}

export default Overview;
