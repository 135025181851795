import React, { Component } from "react";
import { InfoSection, InfoSectionHeader } from "../../common/styledComponents";

class MapLocation extends Component {
  render() {
    const { locationURL } = this.props;
    return (
      <InfoSection>
        <InfoSectionHeader>Map Location</InfoSectionHeader>
        <iframe
          src={locationURL}
          title="Map Location"
          height="450"
          frameBorder="0"
          style={{ border: 0, width: "100%" }}
          allowFullScreen
        />
      </InfoSection>
    );
  }
}

export default MapLocation;
