import React from "react";
import { Icon } from "antd";
import { css } from "emotion";
import { Link } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

const ItemContainer = css`
  border: 1px solid;
  min-height: 300px;
  padding: 0;
  margin-bottom: 25px;
  position: relative;
  background-size: cover;
  color: white;
  background-position: center;

  @media (max-width: 600px) {
    min-height: 200px;
  }

  a {
    color: white !important;

    @media (max-width: 600px) {
      font-size: 10px;
    }
  }

  p {
    font-size: 16px;
    margin-bottom: 0;

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

const PriceContainer = css`
  position: absolute;
  bottom: 1vw;
  margin-bottom: 2vw;
  margin-left: 2vw;
`;

const NameContainer = css`
  ${'' /* margin-top: 2vw; */}
  margin-left: 2vw;
`;

const Price = css`
  font-size: 26px !important;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    font-size: 22px !important;
  }
`;

const overlayCard=css`
  ${'' /* position: relative; */}
  width: 100%;
  ${'' /* border: 1px solid red; */}
  padding-top: 2vw;
  height: 300px;
  background-image: linear-gradient(to top, rgba(0,0,0,0.50), rgba(0,0,0,0.50));
`;

const BestSellerItem = props => (
  <Link to={`/treks/${props.details.slug}`}>
    <div
      className={ItemContainer}
      style={{ backgroundImage: `url(${props.details.image})` }}>
      <div className={overlayCard}>
        <div className={NameContainer}>
          <p>{props.details.name}</p>
          <p>
            {props.details.day} Days
            {/* • {props.details.night}N */}
          </p>
        </div>
        <div className={PriceContainer}>
          <p className={Price}>Rs {props.details.price}</p>
          <p>
            <Link to={`/treks/${props.details.slug}`}>
              View Deal
              <Icon type="right" style={{ marginLeft: 10 }} />
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Link>
);

export default BestSellerItem;
