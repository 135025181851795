import React, { Component, Fragment } from "react";
import {
  InfoSection,
  InfoSectionHeader,
  InfoContent
} from "../common/styledComponents";
import { css } from "emotion";
import Footer from "../common/Footer";
import Header from "../common/Header";

const TestimonialsContainer = css`
  background-color: white;
  min-height: 80vh;
  padding-top: 10%;
  @media (max-width: 600px) {
    padding-bottom: 12%;
  }
`;

class TermsAndConditions extends Component {
  render() {
    return (
      <InfoSection className={TestimonialsContainer}>
        <InfoSectionHeader>Terms and Conditions</InfoSectionHeader>
        <InfoContent>
          <p>
            Himalayan Weekenders is a registered business and all the
            information provided on this website is a property of the company.
          </p>

          <p>
            Any use of information, pictures or copyright infringement on the
            part of any individual or organization will be liable for a legal
            proceeding.
          </p>

          <p>
            Any photograph, video or content (in any form) submitted to
            Himalayan Weekenders by any individual or organization will be
            considered as a property of Himalayan Weekenders.
          </p>

          <p>
            The term ‘us’ or ‘we’ refers to Himalayan Weekenders which is a
            registered entity. The term ‘you’ refers to a user or participant.
          </p>

          <p>
            The information provided on the website relation to treks, trips or
            adventure activities is only for general information and is subject
            to change without any prior notice.
          </p>

          <p>
            As a user of participant of Himalayan Weekenders, the individual
            agrees with the Terms and Conditions mentioned above.
          </p>
        </InfoContent>
      </InfoSection>
    );
  }
}

export default props => (
  <Fragment>
    <Header solid={true} />
    <TermsAndConditions {...props} />
    <Footer />
  </Fragment>
);
