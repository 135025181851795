import React, { Component, Fragment } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import {
  InfoSection,
  InfoSectionHeader,
  InfoContent,
  BackgroundSection,
  BackgroundSectionHeader,
  BackgroundSectionContent
} from "../common/styledComponents";
import { Row, Col, Icon, Button } from "antd";
import { css } from "emotion";

const AnimationButton = css`
  box-shadow: 0px 0px 0px 5px transparent;
  transition: box-shadow 0.2s linear;
`;


const AnimatingCSS = css`
  box-shadow: 0px 0px 0px 10px #00aeef;
`;

const BiggerFont = css`
  font-size: 18px;
  text-align: justify;

  @media only screen and (max-width: 1000px) {
    font-size: 16px;
  }
`;


const img_container=css`
  padding: 0;
`;

const overlay_container=css`
  padding: 10%;
  background-image: linear-gradient(to top, rgba(0,0,0,.3), rgba(0,0,0,.7));
`;

class AboutUs extends Component {
  state = {
    animate: false
  };
  highlightMap = () => {
    this.setState(
      {
        animate: true
      },
      () =>
        setTimeout(() => {
          this.setState({
            animate: false
          });
        }, 1000)
    );
  };
  render() {
    return (
      <div
        className={css`
          margin-bottom: 10%;
        `}
      >
        <BackgroundSection src="https://res.cloudinary.com/hw/image/upload/c_scale,w_1177/v1538397239/About_Us.jpg" className={img_container}>
          <BackgroundSectionContent className={overlay_container}>
            <BackgroundSectionHeader>Who we are?</BackgroundSectionHeader>
            <p className={BiggerFont}>
              Himalayan Weekenders is a rapidly growing community of trekking
              and adventure sports enthusiasts working towards creating a
              sustainable and affordable travel platform for youth. The
              community started in 2016, has expanded exponentially since then.
              Within a span of two years, Himalayan Weekenders has had the
              opportunity of introducing the world of trekking and
              mountaineering to thousands of young people and has been a
              platform for adventure seekers from far and wide.
            </p>
          </BackgroundSectionContent>
        </BackgroundSection>

        <InfoSection>
          <InfoSectionHeader>Why Himalayan Weekenders?</InfoSectionHeader>
          <InfoContent className={BiggerFont}>
            With the proliferation of a culture of trekking and mountaineering,
            there has been a rapid commercialization of the whole sector. This
            has brought several players in the market who are competing to
            provide comfortable and sometimes luxurious adventure sports
            experience to the participants. However, Himalayan Weekenders
            believes that the real experience of trekking and mountaineering can
            only be enjoyed when it is bereft of any artificial luxury.
          </InfoContent>

          <InfoContent className={BiggerFont}>
            Trekking with us will ensure that you learn everything about the
            sport in its raw form. We also believe in creating the best memories
            for our trekkers and thus our trek leaders are always personally
            involved with all the participants taking care of their concerns. We
            strive to ensure that the trekkers build a bond with each other and
            continue to participate in treks in future.
          </InfoContent>

          <InfoContent className={BiggerFont}>
            Lastly, and also most importantly, we understand that adventure
            travel can be an expensive indulgence. Also, with the highly
            commercialized organizations operating in the market, the cost of
            treks have skyrocketed. On the other hand, Himalayan Weekenders
            makes sure that all its trips are budget-friendly and that more and
            more people can experience trekking without having to burn a hole in
            their pockets.
          </InfoContent>
        </InfoSection>

        <Row
          //   gutter={{ sm: 0, xs: 16, md: 0 }}
          className={css`
            padding: 4%;
            background-color: #f3f5f9;
          `}
        >
          <Col md={12} sm={12} xs={24}>
            <InfoSectionHeader>Our Vision</InfoSectionHeader>
            <InfoContent className={BiggerFont}>
              The group was founded with an aim of creating India’s biggest
              community of students involved in adventure activities to build
              outdoor leadership skills in them. Himalayan Weekenders team
              consists of travel enthusiasts who also care deeply for the
              environment and wish to create a culture of sustainable
              travelling. As we grow further, we aspire to involve more young
              people and introduce them to adventure sports in order to help
              them build leadership skills.
            </InfoContent>

            <Button
              size="large"
              onClick={this.highlightMap}
              style={{ marginBottom: "5%" }}
            >
              Come meet use <Icon type="arrow-right" theme="outlined" />
            </Button>
          </Col>
          <Col md={12} sm={12} xs={24}>
            <iframe
              src={
                "https://maps.google.com/maps?q=himalayan%20weekenders&t=&z=13&ie=UTF8&iwloc=&output=embed"
              }
              title="Map Location"
              height="450"
              frameBorder="0"
              style={{ border: 0, width: "100%" }}
              allowFullScreen
              className={`${AnimationButton} ${
                this.state.animate ? AnimatingCSS : ""
              }`}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default props => {
  return (
    <Fragment>
      <Header solid={true} />
      <AboutUs {...props} />
      <Footer />
    </Fragment>
  );
};
