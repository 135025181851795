import React, { Component } from "react";
import {
  InfoSectionHeader,
  InfoSection,
  SectionButtonContainer
} from "../../common/styledComponents";
import { Row, Col, Button } from "antd";
import { css } from "emotion";
import { isMobile } from "../../common/utils";

const PrimaryVideoContainer = css`
  margin-bottom: 16px;
`;

const SecondaryVideoContainer = css`
  margin-bottom: 16px;

  @media (max-width: 600px) {
    /* border: solid 1px #dfdfdf; */
  }
`;

const SecondaryVideos = [
  {
    title: "Top 5 Adventure Sports in Himalayas",
    url: "https://www.youtube.com/embed/-fwOVMvOe0E"
  },
  {
    title: "Top 10 winter snow treks in India",
    url: "https://www.youtube.com/embed/Xf3j66iCdk4"
  },
  {
    title: "New year Mannequin Challenge at Auli",
    url: "https://www.youtube.com/embed/hVjxzIG_yW8"
  }
];

const getVideoView = video => {
  if (!isMobile()) {
    return (
      <Col className={SecondaryVideoContainer} span={8} key={video}>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            title="Feature Videos"
            src={video.url}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
            frameborder="0"
            webkitAllowFullScreen
            mozAllowFullScreen
            allowFullScreen
          />
        </div>
      </Col>
    );
  } else {
    return (
      <Col className={SecondaryVideoContainer} span={24} key={video}>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                title="Feature Videos"
                src={video.url}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                frameborder="0"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
              />
            </div>
          </Col>
          <Col style={{ paddingLeft: 10 }} span={14}>
            {video.title}
          </Col>
        </Row>
      </Col>
    );
  }
};

class FeaturedVideos extends Component {
  render() {
    return (
      <InfoSection>
        <InfoSectionHeader>Our Featured Videos</InfoSectionHeader>
        <Row gutter={16}>
          <Col className={PrimaryVideoContainer} span={24}>
            <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
              <iframe
                title="Feature Videos"
                src="https://www.youtube.com/embed/w1il-bKtHa8"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%"
                }}
                frameborder="0"
                webkitAllowFullScreen
                mozAllowFullScreen
                allowFullScreen
              />
            </div>
          </Col>

          {SecondaryVideos.map(video => getVideoView(video))}
        </Row>
        <SectionButtonContainer>
          <Button type="primary" size="default">
            <a
              href="https://www.youtube.com/channel/UCOaKpcl_HFJoXt9xc6oOztQ?view_as=subscriber"
              target="_blank"
              rel="noopener noreferrer"
            >
              Show All
            </a>
          </Button>
        </SectionButtonContainer>
      </InfoSection>
    );
  }
}

export default FeaturedVideos;
