export const homePageCarousel = [
  {
    name: "Auli Trip",
    price: 6500,
    image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_1440/v1538224860/NEW_Auli_New_year_bash_Homepage.jpg",
    isNew: true
  },
  {
    name: "Dalhousie Trip",
    price: 5500,
    image:
      // "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_1440/v1538224860/KINNAUR_Homepage.jpg",
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_1440/v1542213692/Dalhousie_Christmas_Homepage.jpg",
    isNew: true
  },
  {
    name: "Parashar Lake",
    price: 4000,
    image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,q_80,w_1440/v1547586090/Hompage_Prashar_Lake-min.jpg",
    slug: "parashar"
  },
  {
    name: "Deoriatal-Chandrashila Trek",
    price: 6500,
    image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_1400/v1537383278/treks-deoriatal-chandrashila_ASD_1969.jpg",
    slug: "deoriatal"
  },
  {
    name: "Paragliding at Bir Billing",
    price: 2000,
    image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_1440/v1538224857/Paraglding_birbilling.jpg",
    slug: "paragliding-at-bir"
  },
  {
    name: "Annapurna Base Camp Trek",
    price: 20000,
    image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_1400/v1537631770/Annapurna_Base_camp.jpg",
    slug: "annapurna"
  }
];

export const whereWouldYouLikeToGoData = [
  {
    name: "Triund Trek",
    price: 1999,
    slug: "triund-trek",
    day: 2,
    // night: null,
    image:
      "https://res.cloudinary.com/hw/image/upload/v1537383569/treks-triund_TAR_5023.jpg"
  },
  // {
  //   name: "Hampta Pass Trek",
  //   price: 7499,
  //   slug: "hamta-pass",
  //   day: 5,
  //   // night: null,
  //   image:
  //     "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_440/v1539472325/Hampta_Homepage-min.jpg"
  // },
  // {
  //   name: "Spiti Valley",
  //   price: 15000,
  //   slug: "",
  //   day: 8,
  //   // night: 8,
  //   image:
  //     "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_440/v1537645071/Spiti_Valley-01-2.jpg"
  // },
  // {
  //   name: "Rishikesh Rafting + Camping",
  //   price: 1499,
  //   slug: "rishikesh-river-rafting-trip",
  //   day: 2,
  //   // night: 8,
  //   image:
  //     "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_440/v1615370044/photo-1582811588429-becf2ea63485.jpg"
  // },
  // {
  //   name: "Paragliding at Bir-Billing",
  //   price: 1999,
  //   slug: "paragliding-at-bir",
  //   day: 1,
  //   // night: 8,
  //   image:
  //     "https://res.cloudinary.com/hw/image/upload/v1615369705/IMG_20201025_173358.jpg"
  // },
  // {
  //   name: "Chandratal Trek",
  //   price: 4000,
  //   slug: "chandratal",
  //   day: 3,
  //   // night: null,
  //   image:
  //     "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,h_440/v1537630893/chandratal-01.jpg"
  // },
  {
      name: "Nag Tibba",
      price: 2199,
      slug: "nag-tibba-trek",
      day: 2,
      // night: 8,
      image:
        "https://res.cloudinary.com/hw/image/upload/v1549436306/Trek_Nag%20tibba%206.jpg"
  },
  {
    name: "Parashar Lake",
    price: 2499,
    slug: "parashar",
    day: 2,
    // night: 8,
    image:
      "https://res.cloudinary.com/hw/image/upload/v1547586090/Hompage_Prashar_Lake-min.jpg"
},
  {
    name: "Kheerganga Trek",
    price: 1999,
    slug: "kheerganga",
    day: 2,
    // night: 8,
    image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_440/v1539469770/Kheerganga_Homepage.jpg"
  },
  // {
  //   name: "Kedarkantha Trek",
  //   price: 8000,
  //   slug: "kedarkantha",
  //   day: 5,
  //   // night: null,
  //   image:
  //     "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_440/v1539469771/Kedarkantha_Homepage.jpg"
  // },
  {
    name: "Bhrigu Lake",
    price: 4999,
    slug: "bhrigu-lake",
    day: 4,
    // night: null,
    image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,h_440/v1537383245/treks-bhrigu-lake_b5.jpg"
  },
  {
    name: "Kareri Lake Trek",
    price: 3499,
    slug: "kareri",
    day: 3,
    // night: null,
    image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,h_440/v1537630893/Kareri_lake-01.jpg"
  }
];
