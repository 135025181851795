import React, { Component } from "react";
import {
  BackgroundSection,
  BackgroundSectionContent,
  SectionButtonContainer,
  BackgroundSectionHeader
} from "../../common/styledComponents";
import { Button, Row, Col, Icon, Carousel, Spin } from "antd";
import { TrekMetaInfo, MainContainer } from "./TrekInfo.css";
import { css } from "emotion";

class TrekInfo extends Component {
  scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight + 10,
      behavior: "smooth"
    });
  };
  render() {
    const { name, images, price, difficulty, duration, type } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <Carousel autoplay={true} dots={false}>
          {images ? (
            images.map(image => (
              <div key={image}>
                <BackgroundSection src={image} />
              </div>
            ))
          ) : (
            <BackgroundSection>
              <div
                className={css`
                  min-height: 100vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: white;
                `}
              >
                <Spin
                  indicator={
                    <Icon type="loading" style={{ fontSize: 48 }} spin />
                  }
                />
              </div>
            </BackgroundSection>
          )}
        </Carousel>
        <BackgroundSection style={{ position: "absolute", top: 0 }} className={MainContainer} >
          <BackgroundSectionContent >
            <BackgroundSectionHeader>{name}</BackgroundSectionHeader>
            {type !== "ADVENTURE_TRIP" ? (
              <Row type="flex" justify="center" className={TrekMetaInfo}>
                <Col sm={8} xs={8} md={3} style={{padding:'0 1vw', textAlign:'center'}}>
                  &#8377; {price}
                </Col>
                <Col sm={8} xs={8} md={3} style={{padding:'0 1vw', textAlign:'center'}}>
                  <Icon type="calendar" /> {duration}
                </Col>
                {type === "TREK" ? (
                  <Col sm={8} xs={8} md={3} style={{padding:'0 1vw', textAlign:'center'}}>
                    <Icon type="area-chart" /> {difficulty}
                  </Col>
                ) : null}
              </Row>
            ) : null}
            {images && (
              <SectionButtonContainer>
                <Button type="primary" size="default" onClick={this.scrollDown}>
                  Show All
                  {"  "}
                  <Icon type="arrow-down" theme="outlined" />
                </Button>
              </SectionButtonContainer>
            )}
          </BackgroundSectionContent>
          
        </BackgroundSection>
      </div>
    );
  }
}

export default TrekInfo;
