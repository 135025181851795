import React, { Component, Fragment } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import styled from "react-emotion";
import { css } from "emotion";
import { InfoSectionHeader } from "../common/styledComponents";
import { Button, Collapse } from "antd";
import CareersApplyForm from "./CareersApplyForm";
import { openNotificationWithIcon, saveFormDetails } from "../common/utils";

const Panel = Collapse.Panel;

const JobSection = styled("div")`
  padding: 2% 12%;

  @media only screen and (max-width: 1000px) {
    padding: 2% 6%;
  }
`;

const JobDescription = styled("div")`
  margin-bottom: 4%;
`;

class Careers extends Component {
  state = {
    showModal: false,
    currentJob: "",
    isFormSubmitting: false,
    showGoogleForm: false
  };
  toggleApplyModal = (currentJob, showGoogleForm) => {
    this.setState(state => ({
      showModal: !state.showModal,
      currentJob,
      showGoogleForm
    }));
  };
  onApply = values => {
    console.log("Outer values recieved: ", values);
    this.setState({
      isFormSubmitting: true
    });
    values = JSON.parse(
      JSON.stringify({
        ...values,
        name: this.state.currentJob,
        type: "JOB APPLY"
      })
    );
    saveFormDetails(values)
      .then(response => {
        console.log(response);
        this.setState({
          isFormSubmitting: false
        });
        openNotificationWithIcon("success", {
          message: "Request recieved",
          description:
            "Your request has been recieved. A service agent from our side will be calling you soon!"
        });
        this.toggleApplyModal();
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isFormSubmitting: false
        });
        openNotificationWithIcon("error", {
          message: "Oops!",
          description:
            "Looks like an error on our side. Please contact mail@himalayanweekender.com!"
        });
        this.toggleApplyModal();
      });
  };
  render() {
    const {
      showModal,
      isFormSubmitting,
      currentJob,
      showGoogleForm
    } = this.state;
    return (
      <div
        className={css`
          margin: 150px 0;

          @media only screen and (max-width: 1000px) {
            margin-top: 80px;
          }
        `}
      >
        <CareersApplyForm
          openModal={showModal}
          toggleOpenModal={this.toggleApplyModal}
          onSubmit={this.onApply}
          title={`Apply for ${currentJob}`}
          loading={isFormSubmitting}
          showGoogleForm={showGoogleForm}
        />
        <InfoSectionHeader>Come work with us</InfoSectionHeader>
        {/* 😀 */}
        <JobSection>
          <Collapse bordered={false} accordion={true}>
            <Panel header={<h2>Trek Ambassadors</h2>} key="1">
              <JobDescription>
                The position is open for any student studying at a
                college/university. The Trek Ambassador will act as a link
                between the college and Himalayan Weekenders. Its primary goal
                is to spread awareness in colleges regarding HW’s work and also
                adventure sports in general.
              </JobDescription>
              <h3>Roles and Responsibilities</h3>
              <ul>
                <li>
                  <strong>
                    Contribute in digital marketing, content creation, graphic
                    designing, cinematography, and trek leadership as per your
                    skills and interests.
                  </strong>
                </li>
                <li>
                  Spreading information about adventure sports and travelling
                  among their college students and also promote our activities
                  on the campus through online and offline interactions.
                </li>
                <li>
                  They will be responsible for early management, list
                  preparation, money collection and other important work in case
                  a trip for their college students is planned.
                </li>
                <li>
                  Invite college students to follow/like Himalayan Weekenders
                  social media pages, create whatsapp groups within the college
                  to spread information, and maintain communication with the
                  Students’ Union members and other department representatives.
                </li>
                <li>
                  Plan and organize events in college and sponsor college events
                  (in kind) after having a discussion with the HW team regarding
                  the same.
                </li>
                <li>
                  Put up posters in college regarding the upcoming treks and
                  also share the same on social media platforms.
                </li>
              </ul>

              <h3>Perks and Emoluments</h3>
              <ul>
                <li>
                  Certificate and official letter will be provided to all the
                  trek ambassadors along with Himalayan Weekenders merchandize
                  after a successful completion of the internship
                </li>
                <li>
                  Special discounts will be given to the Ambassadors as they
                  participate in any of the treks organized by Himalayan
                  Weekenders.
                </li>
                <li>
                  Treks will be organized for all the ambassadors on subsidized
                  rates (as per the cost).
                </li>
                <li>
                  Free entry to any trek organized from the college with a
                  minimum participation of at least 20 students.
                </li>
                <li>
                  Learn digital marketing, content creation, graphic designing,
                  cinematography and trek management.
                </li>
              </ul>

              <h3>Terms and Conditions</h3>
              <ul>
                {/* <li>
                  Applicant should be a student of either first or second year.
                </li> */}
                <li>
                  They should have a valid identity proof and address proof.
                </li>
                <li>
                  The applicant should have strong communication skills and
                  should also be active on social media platforms like Facebook
                  and Instagram.
                </li>
                <li>
                  The duration of internship is minimum three months which can
                  be extended after a mutual agreement between both the parties.
                </li>
                <li>
                  The Trek Ambassadors are required to dedicate at least four
                  hours during a week for the HW work.
                </li>
                <li>
                  Every Trek Ambassador has to be present in the meetings
                  organized at HW office in Delhi on fortnightly or monthly
                  basis.
                </li>
                <li>
                  Any amount spent for activities related to Himalayan
                  Weekenders will be reimbursed after furnishing the appropriate
                  bills.
                </li>
                <li>
                  Himalayan Weekenders holds the discretion to terminate the
                  internship after one warning in case of non-performance.
                </li>
              </ul>
              <Button
                type="primary"
                size="large"
                style={{ width: 150 }}
                onClick={() => this.toggleApplyModal("Trek Ambassadors", true)}
              >
                Apply
              </Button>
            </Panel>
            {/* <Panel header={<h2>Digital Marketing Internship</h2>} key="2">
              <JobDescription>
                This is a short-duration internship opportunity which will be
                based on a work-from-home model wherein the interns will be
                expected to increase the number of likes and follows on
                Himalayan Weekenders social media platforms like Facebook,
                Instagram and Youtube.
              </JobDescription>
              <h3>Roles and Responsibilities</h3>
              <ul>
                <li>
                  Every intern will be required to generate 50 Facebook likes,
                  50 Instagram followers and 50 Youtube followers within one
                  month.
                </li>
                <li>
                  The intern must also follow Himalayan Weekenders on all these
                  platforms and share the posts put up during the internship
                  period.
                </li>
                <li>
                  The Internship completion certificate and a letter of
                  appreciation will be provided after the desired results are
                  achieved and a screenshot is shared with HW team.
                </li>
                <li>
                  After a successful completion of the internship, the
                  individuals can also invite others to apply for it and then
                  lead a team of minimum 7 people making sure they achieve all
                  the desired results.
                </li>
                <li>
                  All the interns will be given certificates and the team leader
                  will get an additional certificate acknowledging their
                  leadership and management skills.
                </li>
                <li>
                  Every intern will also get free Himalayan Weekenders
                  merchandize along with discount coupons for the future treks.
                </li>
              </ul>
              <Button
                type="primary"
                size="large"
                style={{ width: 150 }}
                onClick={() =>
                  this.toggleApplyModal("Digital Marketing Internship", false)
                }
              >
                Apply
              </Button>
            </Panel> */}
          </Collapse>
        </JobSection>
      </div>
    );
  }
}

export default props => (
  <Fragment>
    <Header solid={true} />
    <Careers {...props} />
    <Footer />
  </Fragment>
);
