import React, { Component } from "react";
import { InfoSection, InfoSectionHeader } from "../../common/styledComponents";
import { Row, Col } from "antd";
import WhyChooseUsItem from "./WhyChooseUsItem";

class WhyChooseUs extends Component {
  render() {
    return (
      <InfoSection>
        <InfoSectionHeader>Why Choose Us?</InfoSectionHeader>
        <Row type="flex" gutter={16}>
          <Col xs={12} md={8} sm={12} lg={8}>
            <WhyChooseUsItem
              src="https://res.cloudinary.com/dhrgrrgyp/image/upload/v1532812628/web/Best_Experience_Guranteed_2x.svg"
              title="Best Experience Guaranteed"
              content="Himalayan Weekenders has revolutionized the trekking experience by introducing conceptual and experiential trekking. Our treks are designed in such a manner that it puts emphasis on various factors leading to a wholesome experience to make trekking a memorable experience. We let our trekkers explore not only the gems of Himalayas but also themselves. Be it food, camping, adventure activities, group games, surprise challenges, our team strives to make your journey a lifetime experience. We believe that your venture into Himalayas is a special one and we work to make sure that it has a positive and lasting impact on you."
            />
          </Col>
          <Col xs={12} md={8} sm={12} lg={8}>
            <WhyChooseUsItem
              src="https://res.cloudinary.com/dhrgrrgyp/image/upload/v1532812632/web/Spend_Less._Travel_More_2x.svg"
              title="Spend Less, Travel More"
              content="Trekking with Himalayan Weekenders can put stress on your body but never on your wallet. We provide an all inclusive experience with no hidden costs. Our treks are very economical which gives the best value for your money. We make sure that you don’t miss out any exciting place around the treks hence we include various hidden areas around the treks in your itinerary. It gives you an opportunity for exploring the unexplored treasures. Our coordinators make sure that you are always at ease and you get the best out of everything. All this without adding any extra burden on your pocket."
            />
          </Col>
          <Col xs={12} md={8} sm={12} lg={8}>
            <WhyChooseUsItem
              src="https://res.cloudinary.com/dhrgrrgyp/image/upload/v1532812632/web/Top_Rated_Adventure_2x.svg"
              title="Top Rated Adventure Travel Group"
              content="Since its inception Himalayan Weekenders have created a niche for itself because it created a platform where hundreds of people came as strangers and went as family. The conceptual trekking introduced by Himalayan Weekenders has been appreciated by one and all. This is reflected by the rave reviews that we keep getting on social media platforms. We are one of the most highly rated adventure travel group in India on Facebook. An experience with Himalayan Weekenders has had a profound impact on many trekkers whose words of appreciation keeps our team motivated in its endeavours."
            />
          </Col>
          <Col xs={12} md={8} sm={12} lg={8}>
            <WhyChooseUsItem
              src="https://res.cloudinary.com/dhrgrrgyp/image/upload/v1532812628/web/Best_Experience_Guranteed_2x.svg"
              title="Connect With People"
              content="Your Himalayan story will be incomplete if you don’t experience the local culture. During our treks you get ample opportunities to do so be it food, people or culture.Hence our plans include various activities during which you not only interact with your trek fellows but also with local people, visit Himalayan villages, experience local food and know the local culture. Weekenders introduces you not only to Himalayas but its way of life.This not only adds to your experience but also helps you to understand life and challenges of the Himalayan people."
            />
          </Col>
          <Col xs={12} md={8} sm={12} lg={8}>
            <WhyChooseUsItem
              src="https://res.cloudinary.com/dhrgrrgyp/image/upload/v1532812628/web/Learning_Based_Travel_2x.svg"
              title="Learning Based Travel"
              content="Be it the topography and ecosystem of the mountains, heritage and traditions or lifestyle, our team always  keeps you informed. Not only that, while trekking you get to learn of the tricks of the trade. You get to know about trekking and mountaineering techniques, pitching tents, cooking food in mountains and much more. Through group games and activities we try to bring forth your hitherto latent talents. This evolves your group dynamics,leadership skills, motivation levels etc. We try our best to inspire you to make a change through sustainable and responsible trekking."
            />
          </Col>
          <Col xs={12} md={8} sm={12} lg={8}>
            <WhyChooseUsItem
              src="https://res.cloudinary.com/dhrgrrgyp/image/upload/v1532812630/web/Make_Travel_Buddies_2x.svg"
              title="Make Travel Buddies & Create Stories"
              content="Today’s lifestyle is dominated with technology. In such scenario Himalayan Weekenders makes sure that your sojourn in Himalayas is full of stories. Our trekking experience is such that you will make friends with strangers. We believe that best bonds are formed when you are on a trek. We provide you unfathomable opportunities to explore yourself in serene surroundings. Our treks are done in teams, where team members share responsibilities and together they accomplish set targets. The astonishing mountain setup keeps you spellbound all the time and you have a lot to share once you finish the trek."
            />
          </Col>
        </Row>
      </InfoSection>
    );
  }
}

export default WhyChooseUs;
