import firebase from "firebase/app";
import 'firebase/firestore';
import { notification } from "antd";
import emailjs from "emailjs-com";


export const isMobile = () => window.innerWidth <= 600;

export const getDB = () => {
  return firebase.firestore();
};

// function hydrateEmailTemplate(_details) {
//   const details = JSON.parse(JSON.stringify(_details));
//   return `
//     ${details.name.toUpperCase()}
//     Customer Name: ${details.userName};
//     Organisation: ${details.userOrganisation};
//     Email: ${details.userEmailId};
//     Date of Journey: ${new Date(details.userDateOfJourney).toDateString()};
//     Mobile No: ${details.userMobileNo};
//     Group Size: ${details.groupSize};
//   `;
// }

// const makeMailPayload = details => {
//   return {
//     to: "akanksha.sahu1530@gmail.com",
//     text: hydrateEmailTemplate(details),
//     from: "himalayanweekenders@gmail.com",
//     subject: `${details.type}: ${details.name}` || "MAIL FROM WEBSITE"
//   };
// };
function sendEmail(details) {
  emailjs.send('tech_hw', 'default_template', {details :details}, 'user_ygHFzM8Ivhq9Oy778xERw')
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
}

export const saveFormDetails = details => {
  const dateToday= new Date();
  // const tempdate= details.userDateOfJourney
  // console.log(tempdate)
  const newDetails={...details, dateOfQuery:dateToday}
  const db = getDB();  
  return db
    .collection("newTrekRequests")
    .add(newDetails)
    .then(doc => {
      console.log("inside doc")
      // return sendEmail(details);
    });
};


export const openNotificationWithIcon = (type, { message, description }) => {
  notification[type]({
    message,
    description
  });
};

export const isTestEnv = () => {
  return (
    window.location.href.includes("surge") ||
    window.location.href.includes("localhost")
  );
};
