import React, { Component } from "react";
import { Row, Col } from "antd";
import { ContactContainer, ContactNumber, HeaderLink } from "./Header.css";
import { Link } from "react-router-dom";
import LOGO from "../../images/logo.png";
import Instagram from "../../images/insta.svg";
import Facebook from "../../images/facebook.svg";
import Twitter from "../../images/twitter.svg";
import { FooterContainer } from "./Footer.css";
import { css } from "emotion";

const LogoContainer = css`
  @media (max-width: 600px) {
    margin-bottom: 20px;
    border-bottom: 1px solid;
    padding-bottom: 10px;
    text-align: center;
    img {
      width: 120px;
    }
  }
`;

const LinksContainer = css`
  @media (max-width: 600px) {
    margin-bottom: 25px;
  }
`;

const ResponsiveContactContainer = css`
  @media (max-width: 600px) {
    font-size: 12px;
    text-align: center;

    p:first-child {
      font-size: 18px !important;
    }
  }
`;

const BottomText = css`
  margin-top: 15px;
  text-align: center;
`;

class Footer extends Component {
  render() {
    return (
      <div className={FooterContainer}>
        <Row type="flex" align="middle">
          <Col xs={24} sm={24} md={4} className={LogoContainer}>
            <img alt="Logo" width="150" src={LOGO} />
          </Col>
          <Col xs={24} sm={24} md={16} className={LinksContainer}>
            <Row type="flex" align="center" gutter={32} className={HeaderLink}>
              <Col>
                <a
                  href={`https://himalayanweekenders.gonevis.com`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HW Blogs
                </a>
              </Col>
              <Col>
                <Link to="/careers">Careers</Link>
              </Col>
              <Col>
                <Link to="/about-us">About Us</Link>
              </Col>
              <Col>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </Col>
              <Col>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </Col>
            </Row>
          </Col>
          <Col
            className={`${ContactContainer} ${ResponsiveContactContainer}`}
            md={4}
            sm={24}
            xs={24}
          >
            <p className={ContactNumber}>+91 9718095544</p>
            <p>himalayanweekenders@gmail.com</p>
          </Col>
        </Row>
        <hr />
        <Row type="flex" justify="center" gutter={16}>
          <Col>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://facebook.com/himalayanweekenders/"
            >
              <img alt="Facebook" src={Facebook} />
            </a>
          </Col>
          <Col>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://instagram.com/himalayanweekenders/"
            >
              <img alt="Instagram" src={Instagram} />
            </a>
          </Col>
          <Col>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://twitter.com/HWeekenders"
            >
              <img alt="Twitter" src={Twitter} />
            </a>
          </Col>
        </Row>
        <Row type="flex" justify="center" className={BottomText}>
          ® 2018 Himalayan Weekenders New Delhi, 110078, India
        </Row>
      </div>
    );
  }
}

export default Footer;
