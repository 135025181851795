import React, { Component } from "react";
import { Button, Row, Col, Icon, Carousel, Spin } from "antd";
import { css } from "emotion";
import {
  MainContainer,
  HompageSlider,
  ContentContainer,
  ContentHeading,
  HomepageSliderContainer,
  HomepageSliderIndex,
  HompageSliderItem,
  ContentSubHeading
} from "./HomapageCarousel.css";
import { BackgroundSection } from "../../common/styledComponents";
import RegistrationForm from "../../treks/components/RegistrationForm";
import { Link } from "react-router-dom";
import { isMobile } from "../../common/utils";
import { saveFormDetails, openNotificationWithIcon } from "../../common/utils";
import { getDB } from "../../common/utils";

async function getCarouselData() {
  const db = getDB();

  const homeCarouselData = await db
    .collection("hompageCarousel")
    .get()
    .then(snapshot => {
      const items = snapshot.docs.map(d => d.data());
      return items;
    });

  return homeCarouselData;
}


class HomepageCarousel extends Component {
  state = {
    currentItem: null,
    showRegisterModal: false,
    isFormSubmitting: false,
    productName: null,
    homeCarouselPayload: [],
    isLoading: false
  };

  changeDetail = index => {
    this.setState({
      currentItem: this.state.homeCarouselPayload[index]
    });
  };
  setCarouselRef = carousel => {
    console.log(carousel);
    this.carousel = carousel;
  };
  goToSlide = index => {
    this.carousel.slick.slickGoTo(index);
    this.changeDetail(index);
  };
  componentDidMount() {
    let index = 1;
    this.goToSlide(index);
    this.interval = setInterval(() => {
      this.goToSlide(index);
      if (index + 1 >= this.state.homeCarouselPayload.length) {
        index = 0;
      } else {
        index += 1;
      }
    }, 6000);

    this.setState({
      isLoading: true
    });
    getCarouselData().then(data => {
      this.setState({
        homeCarouselPayload: data,
        currentItem: data[0],
        isLoading: false
      });
    });
  }
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  toggleRegisterModal = (name = "") => {
    this.setState(state => ({
      productName: name,
      showRegisterModal: !state.showRegisterModal
    }));
  };

  onSubmitCreateTrekForm = values => {
    console.log("Outer values recieved: ", values);
    this.setState({
      isFormSubmitting: true
    });
    values = JSON.parse(
      JSON.stringify({
        ...values,
        name: this.state.productName,
        type: "REGISTER FOR UPCOMING EVENT"
      })
    );
    saveFormDetails(values)
      .then(response => {
        console.log(response);
        this.setState({
          isFormSubmitting: false
        });
        openNotificationWithIcon("success", {
          message: "Request recieved",
          description:
            "Your request has been recieved. A service agent from our side will be calling you soon!"
        });
        this.toggleRegisterModal();
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isFormSubmitting: false
        });
        openNotificationWithIcon("error", {
          message: "Oops!",
          description:
            "Looks like an error on our side. Please contact mail@himalayanweekender.com!"
        });
        this.toggleRegisterModal();
      });
  };

  render() {
    const {
      currentItem,
      isFormSubmitting,
      showRegisterModal,
      isLoading,
      homeCarouselPayload
    } = this.state;
    return (
      <div style={{ position: "relative" }}>
        <RegistrationForm
          openModal={showRegisterModal}
          toggleOpenModal={this.toggleRegisterModal}
          onSubmit={this.onSubmitCreateTrekForm}
          loading={isFormSubmitting}
        />
        {isLoading ? (
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white"
            }}
          >
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 72 }} spin />}
            />
          </div>
        ) : null}
        <Carousel ref={this.setCarouselRef} autoplay={false} dots={isMobile()}>
          {homeCarouselPayload &&
            homeCarouselPayload.map(({ image }) => (
              <div >
                <BackgroundSection src={image} />
              </div>
            ))}
        </Carousel>
        {/* <div className={temp_container}> */}
        <Row className={MainContainer} style={isLoading ? { zIndex: -1 } : {}}>
          <Col span={24} className={ContentContainer}>
            <p>Featured Package</p>
            <h1 className={ContentHeading}>
              {currentItem ? currentItem.name : ""}
            </h1>
            <p className={ContentSubHeading}>Rs {currentItem ? currentItem.price : ""} Onwards Only*</p>
            {currentItem && currentItem.slug ? (
              <Link to={`/treks/${currentItem ? currentItem.slug : ""}`}>
                <Button type="primary" size="large">
                  Know More
                  <Icon type="right" />
                </Button>
              </Link>
            ) : (
              <Button
                type="primary"
                onClick={() =>
                  this.toggleRegisterModal(currentItem ? currentItem.name : "")
                }
                size="large"
              >
                Register
                <Icon type="right" />
              </Button>
            )}
          </Col>
          <Col span={24} className={HomepageSliderContainer}>
            <Row className={HompageSlider}>
              <hr />
              {this.state.homeCarouselPayload.map((trek, index) => {
                return (
                  <Col span={4} className={HompageSliderItem}>
                    <span onClick={() => this.goToSlide(index)}>
                      <span className={HomepageSliderIndex}>{index + 1}</span>
                      {trek.name}
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        </div>
      // </div>
    );
  }
}

export default HomepageCarousel;
