import React, { Component } from "react";
import { Button, Row, Col } from "antd";
import {
  BackgroundSection,
  BackgroundSectionContent,
  BackgroundSectionHeader,
  SectionButtonContainer,
  BackgroundSectionFooter
} from "../../common/styledComponents";
import { css } from "emotion";
import { isMobile } from "../../common/utils";

const FooterContainer = css`
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2));
  ${'' /* padding: 10px; */}
  hr {
    ${'' /* margin-top: 10vw; */}
    margin-bottom: 25px;
    opacity: 0.5;
    ${'' /* margin: 0 6vw 1vw 6vw; */}
  }
  p {
    ${'' /* margin: 0 6vw 0 6vw; */}
    font-weight: lighter;
    opacity: 0.8;
  }
  a {
    color: #fff;
  }
`;

const SmallerText = css`
  font-size: 46px;
`;

const MainContainer = css`
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.50));
  padding: 5vw 6vw 0 6vw;
  height: max-content;
  ${'' /* border: 5px solid green; */}
`;

const sectionContainer = css`
  padding: 0;
  ${'' /* border: 5px solid green; */}

`;

class OurArticles extends Component {
  getFooterSection() {
    if (!isMobile()) {
      return (
        <Row type="flex" justify="space-between">
          <Col span={8}>
            <p style={{ opacity: 0.5 }}>From Our Team</p>
            <p>
              <a href="https://himalayanweekenders.gonevis.com/five-treks-you-can-try-for-under-rs-5000-only/">
                FIVE TREKS YOU CAN TRY FOR UNDER RS 5000!
              </a>
            </p>
          </Col>
          <Col span={4}>
            <p style={{ textAlign: "right" }}>
              <a
                rel="noopener noreferrer"
                href="https://himalayanweekenders.gonevis.com/"
                target="_blank"
              >
                More Articles&nbsp;&nbsp;&nbsp;&nbsp;
                {">"}
              </a>
            </p>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <p style={{ textAlign: "center" }}>
              <a
                rel="noopener noreferrer"
                href="https://himalayanweekenders.gonevis.com/"
                target="_blank"
              >
                More Articles&nbsp;&nbsp;&nbsp;&nbsp;
                {">"}
              </a>
            </p>
          </Col>
        </Row>
      );
    }
  }
  render() {
    return (
      <BackgroundSection src="https://res.cloudinary.com/hw/image/upload/v1616611071/Solo_Trekker_Guide_Blog_Image.jpg"
      className={sectionContainer}>
        {/* <div className={MainContainer}> */}
        <BackgroundSectionContent className={MainContainer}>
          <p>Featured Article</p>
          <BackgroundSectionHeader className={SmallerText}>
            A COMPLETE GUIDE FOR SOLO TREKKERS!
          </BackgroundSectionHeader>
          <SectionButtonContainer>
            <a
              rel="noopener noreferrer"
              href="https://himalayanweekenders.gonevis.com/a-complete-solo-trekkers-kit/"
              target="_blank"
            >
              <Button type="primary" size="default">
                Read All
              </Button>
            </a>
          </SectionButtonContainer>
        </BackgroundSectionContent>
        <BackgroundSectionFooter className={FooterContainer}>
          <div style={{padding: '0 6vw 1vw 6vw'}}>
            <hr />
            {this.getFooterSection()}
          </div>
        </BackgroundSectionFooter>
        {/* </div> */}
      </BackgroundSection>
    );
  }
}

export default OurArticles;
