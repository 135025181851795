import { css } from "emotion";

export const HeaderContainer = css`
  padding: 15px 5%;
  background: transparent;
  position: fixed;
  top: 0;
  color: white;
  z-index: 1;
  width: 100%;

  hr {
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
`;

export const SolidHeaderContainer = css`
  background: white;
  color: #00aeef;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #d5d5d5;

  hr {
    border-top: 1px solid #00aeef;
  }
`;

export const SearchContainer = css`
  text-align: center;
  font-weight: lighter;

  i {
    margin-right: 8px;
  }
`;

export const ContactContainer = css`
  font-weight: lighter !important;

  p {
    margin-bottom: 0;
  }
`;

export const ContactNumber = css`
  font-size: 20px;
`;

export const HeaderLink = css`
  a {
    color: white !important;
    font-size:16px;
    font-weight: lighter;
  }
`;

export const SolidHeaderLink = css`
  a {
    font-size:16px;
    color: #00aeef !important;
  }
`;

export const SolidSearchContainer = css`
  color: black;
`;

export const SolidContactContainer = css`
  color: black;
`;

export const SidebarTransparentHeader = css`
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background: transparent;
  padding: 5px 15px;
  font-size: 20px;
  color: white;
`;

export const SidebarSolidHeader = css`
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  background: white;
  padding: 10px 15px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06);
  font-size: 20px;
  color: black;
`;
