import styled from "react-emotion";

export const InfoSectionHeader = styled("h2")`
  font-size: 28px;
  opacity: 0.8;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: center;
  color: #000000;
  margin: 0 0 28px 0;
`;

export const InfoSection = styled("div")`
  padding: 4vw 12vw;
  min-height: 60vh;

  @media (max-width: 600px) {
    padding: 6vw 4vw;
  }
`;

export const InfoContent = styled("div")`
  margin: 16px 0;
`;

export const BackgroundSection = styled("div")`
  padding: 2vw 6vw;
  min-height: 100vh;
  max-height: 100vh;
  background: ${props => `url(${props.src})`};
  color: white;
  background-size: cover;
  width: 100%;
  display: table;
  background-position: center;
`;

export const BackgroundSectionContent = styled("div")`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  ${'' /* border: 2px solid blue; */}
`;

export const BackgroundSectionHeader = styled("h2")`
  font-size: 56px;
  margin: auto;
  color: white;
  line-height: 1.35;
  font-family: boldFont, sans-serif;
  padding: 0 10vw;

  @media (max-width: 600px) {
    font-size: 32px;
    padding: 0 4vw;
  }
`;

export const SectionButtonContainer = styled("div")`
  margin-top: 32px;
  text-align: center;
`;

export const NavigationBarButtomContainer = styled("div")`
  margin-top: 5px;
  text-align: center;
  width: 200px;

  @media (max-width: 600px) {
    margin: auto;
  }

  button {
    width: 100%;
  }
`;

export const BackgroundSectionFooter = styled("div")`
  display: table-footer-group;
`;
