import React, { Component } from "react";
import {
   Row,
  Col,
// Button
} from "antd";
import BestSellerItem from "./BestSellerItem";
import {
  InfoSection,
  InfoSectionHeader,
  // SectionButtonContainer
} from "../../common/styledComponents";

import {whereWouldYouLikeToGoData} from './homePageCarouselData'

class BestSellers extends Component {
  render() {
    console.log(whereWouldYouLikeToGoData);
    return (
      <InfoSection>
        <InfoSectionHeader>HW Budget Treks</InfoSectionHeader>
        <Row gutter={16}>
          {whereWouldYouLikeToGoData.map(item => (
            <Col md={8} sm={12} xs={12}>
              <BestSellerItem details={item} />
            </Col>
          ))}
        </Row>
        {/* <SectionButtonContainer>
          <Button type="primary" size="default">
            View All
          </Button>
        </SectionButtonContainer> */}
      </InfoSection>
    );
  }
}

export default BestSellers;
