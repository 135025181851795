import React from "react";
import { Modal, Button, Input, Form, Row, Col } from "antd";

const FormItem = Form.Item;

class CareersApplyForm extends React.Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.props.onSubmit(values);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { showGoogleForm } = this.props;
    if (showGoogleForm) {
      // return (
      //   <Modal
      //     visible={this.props.openModal}
      //     width={800}
      //     onOk={this.props.toggleOpenModal}
      //     onCancel={this.props.toggleOpenModal}
      //     footer={null}
      //   >
      //     <iframe
      //       height={600}
      //       frameBorder="0"
      //       style={{ border: 0, width: "100%" }}
      //       allowFullScreen
      //       src="https://goo.gl/forms/rWU0MJJ08bChxk0B2"
      //     />
      //   </Modal>
      // );
      setTimeout(() => {
        window.open("https://goo.gl/forms/rWU0MJJ08bChxk0B2", "_blank");
      });
      return null;
    }
    return (
      <Modal
        visible={this.props.openModal}
        title={this.props.title}
        onOk={this.props.toggleOpenModal}
        onCancel={this.props.toggleOpenModal}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={this.props.loading}
            onClick={this.onSubmit}
          >
            Submit
          </Button>
        ]}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Name">
              {getFieldDecorator("userName", {
                rules: [{ required: true, message: "Please provide name" }]
              })(<Input />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Organisation/Institution">
              {getFieldDecorator("userOrganisation", {
                rules: [
                  {
                    required: true,
                    message: "Please provide your organisation/institution"
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="City">
              {getFieldDecorator("city", {
                rules: [{ required: true, message: "Please provide your city" }]
              })(<Input style={{ width: "100%" }} />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Email Id">
              {getFieldDecorator("userEmailId", {
                rules: [
                  { required: true, message: "Please provide your email id" },
                  { type: "email", message: "Please provide a valid email id" }
                ]
              })(<Input />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Mobile No.">
              {getFieldDecorator("userMobileNo", {
                rules: [
                  { required: true, message: "Please provide your mobile no." }
                ]
              })(<Input />)}
            </FormItem>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const WrappedCareersApplyForm = Form.create()(CareersApplyForm);

export default WrappedCareersApplyForm;
