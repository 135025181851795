import React, { Component } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import TrekInfo from "./TrekInfo";
import NavigationBar from "./NavigationBar";
import Overview from "./Overview";
import Itinerary from "./Itinerary";
import Testimonials from "../../common/Testimonials";
import MapLocation from "./MapLocation";
import { getDB } from "../../common/utils";

async function getTrekData(slug) {
  const db = getDB();

  const trek = await db
    .collection("products")
    .where("slug", "==", slug)
    .get()
    .then(snapshot => {
      if (snapshot.docs.length) {
        return snapshot.docs[0].data();
      }
      return false;
    });

  return {
    exists: !!trek,
    data: {
      name: trek.name,
      type: trek.type,
      ...trek.details
    }
  };
}

class Treks extends Component {
  state = {
    overview: null,
    itinerary: [],
    mapLocation: null,
    name: null,
    images: null,
    price: null,
    duration: null,
    difficulty: null,
    pickupPoint: null,
    type: null,
    shortItinerary: null,
  };
  componentWillMount() {
    const { slug } = this.props.match.params;
    getTrekData(slug).then(data => {
      if (data.exists) {
        this.setState(data.data);
        // console.log("printing")
        // console.log(data.data);
      } else {
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const { slug: newSlug } = nextProps.match.params;
    const { slug: oldSlug } = this.props.match.params;
    if (newSlug !== oldSlug) {
      getTrekData(newSlug).then(data => {
        if (data.exists) {
          this.setState(data.data);
        } else {
        }
      });
    }
  }

  getNavigationItems() {
    const { itinerary } = this.state;
    return itinerary.length
      ? ["overview", "itinerary", "map location"]
      : ["overview", "map location"];
  }

  render() {
    const {
      overview,
      itinerary,
      mapLocation,
      name,
      duration,
      images,
      difficulty,
      price,
      pickupPoint,
      type,
      shortItinerary,
    } = this.state;
    const navigationItems = this.getNavigationItems();
    return (
      <div>
        <Header />
        <TrekInfo
          price={price}
          duration={duration}
          images={images}
          difficulty={difficulty}
          name={name}
          type={type}
        />
        <NavigationBar
          type={type}
          name={name}
          price={price}
          duration={duration}
          items={navigationItems}
        />
        <Overview
          content={overview}
          type={type}
          price={price}
          pickupPoint={pickupPoint}
        />
        {/* {type === "TREK" ? <hr style={{ width: "75%" }} /> : null} */}
        {type !== "ADVENTURE_TRIP" ? (
          <Itinerary itinerary={itinerary} shortItinerary={shortItinerary} name={name} type={type} />
        ) : null}
        <Testimonials />
        <MapLocation locationURL={mapLocation} />
        <Footer />
      </div>
    );
  }
}

export default Treks;
