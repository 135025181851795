import { css } from "emotion";

export const FooterContainer = css`
  color: white;
  background-image: linear-gradient(260deg, #00aeef, #50c2ff);
  padding: 4% 4% 2% 4%;

  hr {
    margin-top: 2.5%;
    border-top: none;
    margin-bottom: 1.5%;
  }
`;
