import React from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  InputNumber
} from "antd";

const FormItem = Form.Item;

class RegistrationForm extends React.Component {
  onSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log("Received values of form: ", values);
        this.props.onSubmit(values);
      }
    });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        visible={this.props.openModal}
        title="Registeration Form"
        onOk={this.props.toggleOpenModal}
        onCancel={this.props.toggleOpenModal}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={this.props.loading}
            onClick={this.onSubmit}
          >
            Submit
          </Button>
        ]}
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Name">
              {getFieldDecorator("userName", {
                rules: [{ required: true, message: "Please provide name" }]
              })(<Input />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Organisation/Institution">
              {getFieldDecorator("userOrganisation", {
                rules: [
                  {
                    required: true,
                    message: "Please provide your organisation/institution"
                  }
                ]
              })(<Input />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Date Of Trip/Trek">
              {getFieldDecorator("userDateOfJourney", {
                rules: [
                  {
                    required: true,
                    message: "Please provide your date of trip/trek"
                  }
                ]
              })(<DatePicker style={{ width: "100%" }} />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Email Id">
              {getFieldDecorator("userEmailId", {
                rules: [
                  { required: true, message: "Please provide your email id" },
                  { type: "email", message: "Please provide a valid email id" }
                ]
              })(<Input />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Mobile No.">
              {getFieldDecorator("userMobileNo", {
                rules: [
                  { required: true, message: "Please provide your mobile no." }
                ]
              })(<Input />)}
            </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <FormItem label="Group Size">
              {getFieldDecorator("groupSize", {
                rules: [
                  { required: true, message: "Please provide your group size" }
                ]
              })(<InputNumber style={{ width: "100%" }} />)}
            </FormItem>
          </Col>
        </Row>
      </Modal>
    );
  }
}

const WrappedRegistrationForm = Form.create()(RegistrationForm);

export default WrappedRegistrationForm;
