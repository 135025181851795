import React, { Component } from "react";
import {
  InfoSection,
  InfoSectionHeader,
  InfoContent
} from "../../common/styledComponents";
import { Row, Col, Button } from "antd";
import { css } from "emotion";
import styled from "react-emotion";
import WrappedItineraryForm from "./ItineraryForm";
import { saveFormDetails, openNotificationWithIcon } from "../../common/utils";

const NumberedCircle = styled("span")`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #00aeef;
  text-align: center;
  line-height: 1.8;
  font-size: 22px;
  display: inline-block;
  color: #00aeef;

  @media (max-width: 600px) {
    width: 28px;
    height: 28px;
    font-size: 18px;
    line-height: 1.4;
    margin-top: 6px;
  }
`;

const ContentHeader = styled("p")`
  font-size: 22px;
  line-height: 1.8;
  margin-bottom: 10px;
  color: black;
`;

const ContentBody = styled("p")`
  line-height: 1.8;
`;

const DashedLine = styled("p")`
  height: 70%;
  border-left: 1px dashed;
  width: 2px;
  margin: auto;

  @media (max-width: 600px) {
    height: 85%;
  }
`;

const ShapeContainer = css`
  text-align: center;
  display: flex;
  padding-left: 4%;
  flex-direction: column;
  text-align: center;

  @media (max-width: 600px) {
    padding-left: 0;
  }
`;

const ItemHeader = css`
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const ItemBody = css`
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

const ItineraryItem = props => (
  <Row type="flex">
    <Col className={ShapeContainer} span={2}>
      <NumberedCircle>{props.day}</NumberedCircle>
      <DashedLine />
    </Col>
    <Col span={21} offset={1}>
      <ContentHeader className={ItemHeader}>Day {props.day}</ContentHeader>
      {props.content.map(c => (
        <ContentBody className={ItemBody}>{c}</ContentBody>
      ))}
    </Col>
  </Row>
);

const MAX_ITEM_NUM = 3;

class Itinerary extends Component {
  state = {
    showingAll: false,
    openModal: false,
    isFormSubmitting: false
  };
  getItineraryItem = (item, index, { length }) => {
    const { showingAll } = this.state;
    if (showingAll) {
      return (
        <ItineraryItem
          content={item.content}
          day={index + 1}
          isLast={index + 1 === length}
        />
      );
    } else {
      if (index < MAX_ITEM_NUM) {
        return <ItineraryItem content={item.content} day={index + 1} />;
      }
      return null;
    }
  };
  toggleShowAll = () => {
    this.setState(state => ({
      showingAll: !state.showingAll
    }));
  };

  toggleOpenModal = () => {
    this.setState(state => ({
      openModal: !state.openModal
    }));
  };
  onSubmitCreateTrekForm = values => {
    console.log("Outer values recieved: ", values);
    this.setState({
      isFormSubmitting: true
    });
    values = JSON.parse(
      JSON.stringify({
        ...values,
        name: this.props.name,
        type: "CREATE YOUR OWN TREK"
      })
    );
    saveFormDetails(values)
      .then(response => {
        console.log("val"+values);
        this.setState({
          isFormSubmitting: false
        });
        openNotificationWithIcon("success", {
          message: "Request recieved",
          description:
            "Your request has been recieved. A service agent from our side will be calling you soon!"
        });
        this.toggleOpenModal();
      })
      .catch(err => {
        console.log(err);
        this.setState({
          isFormSubmitting: false
        });
        openNotificationWithIcon("error", {
          message: "Oops!",
          description:
            "Looks like an error on our side. Please contact himalayanweekenders@gmail.com!"
        });
        this.toggleOpenModal();
      });
  };
  render() {
    const { showingAll, isFormSubmitting } = this.state;
    const { itinerary, type, shortItinerary } = this.props;
    // console.log(shortItinerary);
    return (
      <div>
        {(shortItinerary != "" && shortItinerary != null) ?
        (<div style={{width:'500px',margin:'auto', alignContent:'center'}}>
          <InfoSectionHeader>Short Itinerary</InfoSectionHeader>
          <img src={shortItinerary} 
            style={{width: '500px', height:'411px', alignSelf:'center',pointerEvents: 'none'}}>
          </img>
        </div>) : null}
        <WrappedItineraryForm
          openModal={this.state.openModal}
          toggleOpenModal={this.toggleOpenModal}
          onSubmit={this.onSubmitCreateTrekForm}
          loading={isFormSubmitting}
        />
        {itinerary && itinerary.length ? (
          <InfoSection>
            <InfoSectionHeader>Detailed Itinerary</InfoSectionHeader>
            {itinerary.map(this.getItineraryItem)}
            {itinerary.length > 3 ? (
              <div style={{ paddingLeft: "4%" }}>
                <Button
                  type="primary"
                  size="large"
                  onClick={this.toggleShowAll}
                >
                  {showingAll ? "Show Less" : "Show More"}
                </Button>
              </div>
            ) : null}
            {type === "TRIP" ? (
              <Button
                type="primary"
                size="large"
                style={{ marginTop: "24px" }}
                onClick={this.toggleOpenModal}
              >
                Customize your own trip
              </Button>
            ) : null}
          </InfoSection>
        ) : (
          <InfoSection style={{ minHeight: "30vh" }}>
            <InfoSectionHeader>Itinerary</InfoSectionHeader>
            <InfoContent style={{ textAlign: "center", fontSize: "20px" }}>
              Please mail us for detailed itinerary at{" "}
              <a href="mailto:himalayanweekenders@gmail.com">
                himalayanweekenders@gmail.com
              </a>{" "}
              <br />
              {type === "TRIP" ? (
                <Button
                  type="primary"
                  size="large"
                  style={{ marginTop: "24px" }}
                  onClick={this.toggleOpenModal}
                >
                  Customize your own trip
                </Button>
              ) : null}
            </InfoContent>
          </InfoSection>
        )}
      </div>
    );
  }
}

export default Itinerary;
