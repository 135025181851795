import React from "react";
import { css } from "emotion";
// import { Popover } from "antd";
// import { isMobile } from "../../common/utils";

const WhyChooseUsItemContainer = css`
  text-align: center;
  border: solid 1px #e5e5e5;
  padding: 14%;
  margin-bottom: 16px;
  height: 92%;
  cursor: pointer;

  h3 {
    font-size: 18px;
    line-height: 1.4;
    color: #000000;
    margin-top: 10px;
    cursor: pointer;
  }
`;

const WhyChooseUsItem = props => (
  <div className={WhyChooseUsItemContainer}>
    {/* <Popover
      content={(<div style={{ width: 270, display: isMobile() ? 'contents' : 'block' }}>{props.content}</div>)}
      placement={"top"}
    > */}
    <img alt={props.title} width="65" height="70" src={props.src} />
    <h3>{props.title}</h3>
    {/* </Popover> */}
  </div>
);

export default WhyChooseUsItem;
