import React, { Component } from "react";
import HomepageCarousel from "./HomepageCarousel";
import Header from "../../common/Header";
import BestSellers from "./BestSellers";
import FeaturedVideos from "./FeaturedVideos";
import OurArticles from "./OurArticles";
import WhyChooseUs from "./WhyChooseUs";
import Testimonials from "../../common/Testimonials";
import Footer from "../../common/Footer";

class Home extends Component {
  render() {
    return (
      <div>
        <Header />
        <HomepageCarousel />
        <BestSellers />
        <hr style={{ width: "60%" }} />
        <FeaturedVideos />
        <OurArticles />
        <WhyChooseUs />
        <Testimonials />
        <Footer />
      </div>
    );
  }
}

export default Home;
