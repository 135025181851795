import React, { Component } from "react";
import { InfoSection, InfoSectionHeader } from "./styledComponents";
import Slider from "react-slick";
import styled from "react-emotion";
import { Icon, Row, Col } from "antd";
import { css } from "emotion";

const SliderArrowStyle = css`
  &::before {
    visibility: hidden !important;
  }
  i {
    color: black;
    font-size: 25px;
    border: 1px solid white;
    border-radius: 50%;
    background-color: white;
    padding: 12px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    color: #979797;
  }
`;

const TestimonialsItem = styled("div")`
  border-radius: 30px 30px 30px 0;
  background-color: #ffffff;
  /* box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.06); */
  padding: 10%;
`;

const TestimonialsContainer = css`
  background-color: #f3f5f9;
  @media (max-width: 600px) {
    padding-bottom: 12%;
  }
`;

const TESTIMONIALS = [
  {
    title: "Pallavi Saha",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383154/testimonials_Pallavi%20Saha.jpg",
    subTitle: "Delhi University",
    url: "#",
    content:
      "We planned our first trek with himalayan weekenders. These guys are amazing. They guided and helped us throughout the trek. I don't think this trek could have been possible without them especially Namit. The food was great. They even provided us with medicines when required. Overall it was a great experience."
  },
  {
    title: "Mary Toppo",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383146/testimonials_Mary%20Toppo.jpg",
    subTitle: "Delhi University",
    url: "#",
    content:
      "It was my first trek and they made it even more special. They planned the trek really well. Thank you Himalayan Weekenders. Specially Namit - the way he took care and encouraged us. Seriously had a wonderful time with them. #triundtrek #unforgettablememories"
  },
  {
    title: "Sneh Nehra",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383184/testimonials_Sneh%20Nehra.jpg",
    subTitle: "Amity University, Gurugram",
    url: "#",
    content: `Well it's hard to define the experience in words I just love the hospitality environment which Ankush provided. He is the guy who don’t let you fell that you are a group of people who joined them for trip he is like friend to you and you feel that you came out with your friends trip started at 18 may from Delhi I really like the place Though I was the only person to reach the indrahar pass but he is the one who will be there for you he is the best guide a brother to me .
    I wish those day come back well I am eagerly waiting for the Goa trip to start 
    #Himalayanweekenders you rock thanks Ankush Yadav bro`
  },
  {
    title: "Rashi Jindal",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383161/testimonials_Rashi%20Jindal.jpg",
    subTitle: "",
    url: "#",
    content: ` A journey is best measured in friends, rather than miles.
    A memorable vacation is one that you can never forget. Sometimes it is even hard not to stop thinking about it and this much needed vacation ws damn awesome. The credit goes to Sanjay Kumar Jha and the whole team of Himalayan Weekenders😍😍
    U guys are such a support ❤️`
  },
  {
    title: "Anshuman Sharma",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383134/testimonials_Anshuman%20Sharma.jpg",
    subTitle: "Bits Pilani",
    url: "#",
    content:
      "Here's to an enriching, liberating and an absolutely rocking trip to Indrahara Pass. Travelling, though already liberating, becomes a once-a-while experience when you have great accomplices. Just the kind I had. Especially Ankush from Himalayan Weekenders. Great guy, chilled out, always helpful. Keep rocking buddy. If first impression is anything to go by, then I'm soon gonna be backpacking through some new adventure with you guys. We thought we were just having fun , all this while, when we were actually making memories . See ya again."
  },
  {
    title: "Aarti Chauhan",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383125/testimonials_Aarti%20Chauhan.jpg",
    subTitle: "Amity University",
    url: "#",
    content: `It’s been three days we are back from the trip.. Trek to indrahara. I am still in that hangover that masti music dance camping bonfire sights views that place mesmerising. Thanks to dibya and Ankush Yadav who made it the way it was.. Really awesome we gonna plan another trip with uh guys definitely. I am still enjoying with the memories we made there triund" the best place ever. Really thankyou so much for taking care of us that much mr ankush and making this trip memorable for us we had so much fun there missing uh guys.. Also bits pilani…. awesome trip enjoyed a lot can't even explain in words.. Memories are forever`
  },
  {
    title: "Momina Khan",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383151/testimonials_Momina%20Khan.jpg",
    subTitle: "Delhi University",
    url: "#",
    content: `Such an amazing trip!!! I have never seen such a beautiful place...and I will keep going there as I am so much in love. Thanks to the organisers who were more like friends. Well organized, so much fun and their initiatives to keep us happy throughout were commendable. I owe my accomplished trek to Sanjay.... I wouldn't have done this without you.`
  },
  {
    title: "Megha Patel",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383149/testimonials_Megha%20Patel.jpg",
    subTitle: "Delhi University",
    url: "#",
    content: `Though it wasn't my first trek but the experience was all new and amazing . I had never thought that trekking could be that much interesting and all credit for that goes to of course Sanjay Kumar and Ankush Yadav . You both are amazing be it coordination , organizing things for us , to motivate us , and as fellow trekkers . You both kept us enthusiastic and energetic .
    All activities were amazing and #himalayanweekenders has fulfilled all our expectations .
    Thank you for organizing this trip guys ! I wish you all the good luck for your future trips :)
    `
  },
  {
    title: "Soumya Sharma",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383186/testimonials_Soumya%20Sharma.jpg",
    subTitle: "Delhi University",
    url: "#",
    content: `Want to experience the peace, beauty and tranquility of the mountains... This is so far the best way.. Trekking and hiking to the triund, McLeodganj, bhagsu nag. I had never expected such a trip where I could push the boundaries of my strength to experience something so beautiful. Had been to the himachal and Kashmir many times before.. But this trip was entirely different. Extremely exciting and fun-filled. Thanks to the venture of Sanjay Kumar and Ankush Yadav that they give such opportunities to youngsters at reasonable costs. The parties, camping at the top, bonfire and interactions with people all through the trip was indeed memorable. Given a chance to trek with them... Please avail then and there. Thanks a ton guys :)`
  },
  {
    title: "Swati Aggarwal",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383192/testimonials_Swati%20Aggarwal.jpg",
    subTitle: "Snapdeal",
    url: "#",
    content: `“My First Trek into the mountains.. An Awesome One!”Thanks to himalayan weekenders to make it as most memorable experience of my life. The arrangements were exceptionally good and I didn’t expect such organised and delicious food and snacks. Organizers especially Sanjay and Mohit were really good, the way they were supporting while trekking, gave me more push. All thanks to all the members of himalayan weekend which made it possible for me. Looking forward for next trip.`
  },
  {
    title: "Raghubendra Singh",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383158/testimonials_Raghubendra%20Singh.jpg",
    subTitle: "Nepal",
    url: "#",
    content: `The trip you plan is up to you but what remains with you is the main. I captured a lot of awesome memories with two awesome guys @mohit rathee and @sanjay bhai..really great guys. They not only make our trip successful one but made us feel that each and every moment you live is to live with all your passion. Give them an opportunity to put a little warmth in your life. I will going to make it happen soon.`
  },
  {
    title: "Shivangi Pandey",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383179/testimonials_Shivangi%20Pandey.jpg",
    subTitle: "Delhi University",
    url: "#",
    content: `It was my first trek and Himalayan weekenders made it even more special and amazing. These guys were so encouraging and feels like family. Right from the arrangements to food everything was 5/5.`
  },
  {
    title: "Pranshu Goel",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383156/testimonials_Pranshu%20Goel.jpg",
    subTitle: "IHM Mumbai",
    url: "#",
    content: `My first moderate trek to Hampta pass and Chandrataal lake in such a budget friendly package. All thanks to Sanjay Bhaiya. He was really kind and helpful. Thumbs up to Himalayan Weekenders!!!!`
  },
  {
    title: "Manu Nigotia",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383144/testimonials_Manu%20Nigotiya.jpg",
    subTitle: "Salesforce",
    url: "#",
    content: `Himalayan Weekenders is beyond awesome. Such affordable rates can only be thought of. Besides, such amicable and amazing people make your journey cherishable for lifetime. At each step, you'll find these people by your side, encouraging and motivating you. In a nutshell, Himalayan Weekenders is without a second thought, the best. Love these guys. Keep up the work brothers.`
  },
  {
    title: "Aniruddha Newaskar",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383131/testimonials_Aniruddha.jpg",
    subTitle: "NIT Allahabad",
    url: "#",
    content: `One of the best trekking experience in my life. They planned the trek really well, the places to stay and the food was also quite awesome. But most importantly, it was because of the team of Himalaya Weekenders which gave us such memories which will be cherished forever. Specially recommend Sanjay bhai whose presence made the trek much much better.`
  },
  {
    title: "Himanshu Patel",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383141/testimonials_Himanshu%20Patel.jpg",
    subTitle: "VISA",
    url: "#",
    content: `Himalayan Weekenders is just AMAZING. Food and stay were awesome. Enjoyed the trip a lot and the team made it unforgettable. It was one of the best experience of my life. I would recommend it to all young people and if you are a student then Himalayan Weekenders is the best choice for an adventurous trip.
    Thank you Himalayan Weekenders for such memorable moments.`
  },
  {
    title: "Riyaz Panjwani",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383163/testimonials_Riyaz.jpg",
    subTitle: "VISA",
    url: "#",
    content: `This was my first trek !! I could proudly say that those were one of the most memorable days of my life  !! I couldn't thank more ... I appreciate the kind of hard work put in by all the team members of Himalayan Weekenders for making our Trek a huge success and something we look back to !! Kudos to the Team Of Himalayan Weekenders ... Right from boarding the bus from Delhi to being venerated way back down from Indrahara Pass, the team was always with us and helped us achieve something we never thought we could have done !!`
  },
  {
    title: "Samridhi Sikri",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383173/testimonials_Samridhi%20Sikri.jpg",
    subTitle: "",
    url: "#",
    content: ` I had a great time during my trip and it was one of the best experiences ever. Thankyou Himalayan Weekenders for providing the best of everything from food to places we stayed and of course the team members . I had a blast . Looking forward to more such trips . The whole trip was worth every penny .`
  },
  {
    title: "Shubhransh Srivastava",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383170/testimonials_SHUBHRANSH.jpg",
    subTitle: "Arista Networks",
    url: "#",
    content: `Awesome people... Awesome experience... First trek of my life was made memorable because of them. They managed the group quite well, kept track of everyone's health and above all kept the journey enjoyable. Great food... Great folks... Keep up the good work`
  },
  {
    title: "Diksha Ravi",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383136/testimonials_Diksha%20Ravi.jpg",
    subTitle: "",
    url: "#",
    content: ` I had the best trip of my life with Himalayan weekenders. The whole team is really hard working, enthusiastic and full of life. I was astounded by the passion they have for nature. The trip memories will always be with me. The best four days of my life. Really looking forward for another adventurous trip with them.`
  },
  {
    title: "Gulshana",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383138/testimonials_Gulshana.jpg",
    subTitle: "Vibes Communications",
    url: "#",
    content: `Himalayan weekenders are an excellent Travel Planner for us as they considered our unique needs during planning of our itinerary. Every suggestion they made was excellent, as they considered time constraints, and personal likes and dislikes. I just wanted to thank you and let you know I will definitely contact you again before our next big trip. We can't thank you enough for making our vacation a pleasant experience, with a lot of good memories to cherish and share with our family and friends. We had a great trip and appreciate all your hard work! Cheers`
  },
  {
    title: "Ruchi Sachan",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383166/testimonials_Ruchi%20Sachan.jpg",
    subTitle: "JNU",
    url: "#",
    content: `Thank you' would be too small a word, to describe the experiences we had. The team is passionate about what they do and it is well reflected in the unforgettable memories we made. There would be no exaggeration if I say that Himalayan Weekenders is the best, amongst the lot. Respect for the team's dedication and love to these lovely people. Looking forward to more of such trips.`
  },
  {
    title: "Shruti Garg",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383181/testimonials_Shruti%20Garg.jpg",
    subTitle: "Lekker Baker",
    url: "#",
    content: ` I have never seen any trip organisers so dedicated to their job. People at Himalayan Weekenders don't work for themselves, they work for us, they work so hard to make the trip memorable for us. I truly admire their spirit and organising skills. Had the best trip of my life to Auli `
  },
  {
    title: "Shadab Khan",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383176/testimonials_Shadab%20Khan.jpg",
    subTitle: "Gold Gym",
    url: "#",
    content: `First time I got chance to have a trip with Himalayan Weekenders in Kullu-Manali and it was awesome. I am short of words to explain how this trip filled us with enthusiasm. It really gave an amazing experience. And the most active guy "Ankush Yadav" never let us lose hope while trekking. All activities were awesome. I am waiting for the next trip to go with Himalayan weekenders. You made this trip an unforgettable memory. YOU ARE EXTREMELY AWESOME.`
  },
  {
    title: "Yuthika Venugopal",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383197/testimonials_Yuthika%20Venugopal.jpg",
    subTitle: "Amity University, Noida",
    url: "#",
    content: `It was an amazing trip and Himalayan Weekenders made our trip even more amazing and worth it. They made us experience different adventure sports and gave the best possible memories of manali and the trip. They supported us at every step and motivated us and were more as friends to us. Keep it up. All the best. And Thank you. `
  },
  {
    title: "Ahana Rawat",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383128/testimonials_Ahana%20Rawat.jpg",
    subTitle: "ITC, Mumbai",
    url: "#",
    content: `This was my first trek and Himalayan Weekenders made it more special! Seriously would recommend them to everyone who wants to enjoy a trip to the mountains as they give the best company and facilities! Looking forward for the next trek with them!`
  },
  {
    title: "Vijeta Ranawat",
    profile_image:
      "https://res.cloudinary.com/hw/image/upload/c_fill,f_auto,w_42/v1537383195/testimonials_Vijeta%20Ranawat.jpg",
    subTitle: "IPCW, Mumbai",
    url: "#",
    content: `The experience was one that cannot be explained in words or pictures it can only be felt.  The planning and everything was very good but the best part is the dedication these people had towards us. Himalayan weekenders is the best to plan a trip with.`
  }
].slice(0, 12);

const SliderArrow = props => {
  const { className, style, onClick, type } = props;
  return (
    <div
      className={`${className} ${SliderArrowStyle}`}
      style={style}
      onClick={onClick}
    >
      {type === "next" ? (
        <Icon type="arrow-right" />
      ) : (
        <Icon type="arrow-left" />
      )}
    </div>
  );
};

class Testimonials extends Component {
  settings = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SliderArrow type="next" />,
    prevArrow: <br />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          nextArrow: null,
          prevArrow: null,
          dots: true
        }
      }
    ]
  };
  render() {
    return (
      <InfoSection className={TestimonialsContainer}>
        <InfoSectionHeader>From Our Satisfied Trekkers :)</InfoSectionHeader>
        <Slider {...this.settings}>
          {TESTIMONIALS.map(t => (
            <div className="slide-container" key={t.title}>
              <TestimonialsItem>{t.content}</TestimonialsItem>
              <Row type="flex" gutter={16} style={{ marginTop: "17.7px" }}>
                <Col span={4}>
                  <img
                    src={t.profile_image}
                    style={{
                      width: "42px",
                      height: "42px",
                      borderRadius: "24px",
                      marginLeft: 2
                    }}
                    alt="userProfile"
                  />
                </Col>
                <Col span={20}>
                  <p
                    style={{
                      fontSize: "16px",
                      lineHeight: "1.25",
                      color: "#000000",
                      marginBottom: 0,
                      marginTop: 4
                    }}
                  >
                    {t.title}
                  </p>
                  <p
                    style={{
                      opacity: "0.5",
                      fontSize: "12px",
                      lineHight: "1.33",
                      color: "#000000"
                    }}
                  >
                    {t.subTitle}
                  </p>
                </Col>
              </Row>
            </div>
          ))}
        </Slider>
      </InfoSection>
    );
  }
}

export default Testimonials;
