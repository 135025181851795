export const FIREBASE_STAGING_CONF = {
  apiKey: "AIzaSyC5ug_jyqdo8CkP_z8GqLdU_lQM6kM6kzU",
  authDomain: "hw-stag.firebaseapp.com",
  databaseURL: "https://hw-stag.firebaseio.com",
  projectId: "hw-stag",
  storageBucket: "hw-stag.appspot.com",
  messagingSenderId: "241111925552"
};


// export const FIREBASE_STAGING_CONF = {
//   apiKey: "AIzaSyBcyZzbwob16FnY679TK5xXmQwGUAUmt9s",
//   authDomain: "hw-prod-76c19.firebaseapp.com",
//   databaseURL: "https://hw-prod-76c19.firebaseio.com",
//   projectId: "hw-prod-76c19",
//   storageBucket: "",
//   messagingSenderId: "743227911438"
// };


export const FIREBASE_PROD_CONF = {
  apiKey: "AIzaSyBcyZzbwob16FnY679TK5xXmQwGUAUmt9s",
  authDomain: "hw-prod-76c19.firebaseapp.com",
  databaseURL: "https://hw-prod-76c19.firebaseio.com",
  projectId: "hw-prod-76c19",
  storageBucket: "",
  messagingSenderId: "743227911438"
};
