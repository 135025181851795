import React, { Component, Fragment } from "react";
import {
  InfoSection,
  InfoSectionHeader,
  InfoContent
} from "../common/styledComponents";
import { css } from "emotion";
import Footer from "../common/Footer";
import Header from "../common/Header";

const TestimonialsContainer = css`
  background-color: white;
  min-height: 80vh;
  padding-top: 10%;
  @media (max-width: 600px) {
    padding-bottom: 12%;
  }
`;

class PrivacyPolicy extends Component {
  render() {
    return (
      <InfoSection className={TestimonialsContainer}>
        <InfoSectionHeader>Privacy Policy</InfoSectionHeader>
        <InfoContent>
          <p>
            The privacy policy covers all the aspects related to handling of
            information provided by participants to Himalayan Weekenders while
            registering for a trek.
          </p>

          <p>
            The policy is also applicable to anyone who contacts us through our
            social media platforms or other channels of communication.
          </p>

          <p>
            Himalayan Weekenders does not share the information it collects
            about the participants (contact number, email id, social media
            account, address and identity proof) with any third party.
          </p>

          <p>
            Any other sensible information like credit/debit card details are
            not collected or stored by Himalayan Weekenders in any form.
          </p>

          <p>
            The contact details can be used by Himalayan Weekenders to
            communicate with the participants in future. The participants can
            opt out of it by sending an email to us.
          </p>

          <p>
            As a user of participant of Himalayan Weekenders, the individual
            agrees with the Privacy Policy mentioned above.
          </p>
        </InfoContent>
      </InfoSection>
    );
  }
}

export default props => (
  <Fragment>
    <Header solid={true} />
    <PrivacyPolicy {...props} />
    <Footer />
  </Fragment>
);
