import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./modules/home";
import Treks from "./modules/treks/";
import firebase from "firebase/app";
import {
  FIREBASE_STAGING_CONF,
  FIREBASE_PROD_CONF
} from "./modules/common/constants";
import "firebase/firestore";
import PrivacyPolicy from "./modules/extras/PrivacyPolicy";
// import seed from "./server/seed";
import { isTestEnv } from "./modules/common/utils";
import AboutUs from "./modules/extras/AboutUs";
import Careers from "./modules/extras/Careers";
import TermsAndConditions from "./modules/extras/TermsAndConditions";

class App extends Component {
  constructor(props) {
    const config = isTestEnv() ? FIREBASE_STAGING_CONF : FIREBASE_PROD_CONF;
    // const config = FIREBASE_PROD_CONF;
    firebase.initializeApp(config);
    // seed();
    super(props);
  }
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/treks/:slug" component={Treks} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/careers" component={Careers} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
