// TODO: Find out a better way to do theming

import React, { Component, Fragment } from "react";
import { Row, Col, Icon, Dropdown, Menu, Select } from "antd";
import {
  HeaderContainer,
  SearchContainer,
  ContactContainer,
  ContactNumber,
  HeaderLink,
  SolidHeaderContainer,
  SolidHeaderLink,
  SolidSearchContainer,
  SolidContactContainer,
  SidebarTransparentHeader,
  SidebarSolidHeader
} from "./Header.css";
import { Link } from "react-router-dom";
import LOGO from "../../images/logo.png";
import SOLID_LOGO from "../../images/solid_logo.png";
import { isMobile, getDB } from "./utils";
import { css } from "emotion";

const Drawer = ({ visible, children, onClose }) => {
  if (!visible) {
    return null;
  }
  return (
    <div
      className={css`
        width: ${window.innerWidth - 100}px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        overflow: scroll;
        z-index: 4;
        min-height: 100vh;
        background: white;
      `}
    >
      <div
        className={css`
          text-align: right;
          padding: 5px 10px;
          font-size: 28px;
          margin-bottom: 5px;
        `}
      >
        <Link to="/" >
          <img alt="Logo" width="150" src={SOLID_LOGO} 
          style={{position:'absolute', left:'24px'}}/>
        </Link>
        <Icon type="close" onClick={onClose} />
      </div>
      <div>{children}</div>
    </div>
  );
};

const Option = Select.Option;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const getDropMenu = (items, name = "name", link = "slug") => {
  return (
    <Menu>
      {items.map(item => (
        <Menu.Item key={item.link}>
          <Link to={`/treks/${item[link]}`}>{item[name]}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

const getMobileDropdown = (items,toggleSidebar, name = "name", link = "slug") => {
  return (
    <MenuItemGroup>
      {items.map(item => (
        <Menu.Item key={item.link}>
          <Link onClick={toggleSidebar} to={`/treks/${item[link]}`}>{item[name]}</Link>
        </Menu.Item>
      ))}
    </MenuItemGroup>
  );
};

const getMoreDropdownMobile = (toggleSidebar) => {
  return (
    <MenuItemGroup>
      <Menu.Item key="about-us">
          <Link onClick={toggleSidebar} to={`/about-us`}>About us</Link>
        </Menu.Item>
        <Menu.Item key="careers">
          <Link onClick={toggleSidebar} to={`/careers`}>Careers</Link>
        </Menu.Item>
        <Menu.Item key="blogs">
        <a
          href={`https://himalayanweekenders.gonevis.com`}
          rel="noopener noreferrer"
          target="_blank"
          onClick={toggleSidebar}
        >
          HW Blogs
        </a>
        </Menu.Item>
    </MenuItemGroup>
  );
};

const getMoreDropdownDesktop = () => {
  return (
    <Menu>
        <Menu.Item key="about-us">
          <Link to={`/about-us`}>About us</Link>
        </Menu.Item>
        <Menu.Item key="careers">
          <Link to={`/careers`}>Careers</Link>
        </Menu.Item>
        <Menu.Item key="blogs">
        <a
          href={`https://himalayanweekenders.gonevis.com`}
          rel="noopener noreferrer"
          target="_blank"
        >
          HW Blogs
        </a>
        </Menu.Item>
    </Menu>
  );
};

const DesktopHeader = ({
  theme,
  treks,
  trips,
  adventureTrips,
  weekendGetaways,
  options,
  searchValue,
  handleSearchValueChange,
  handleSearch
}) => (
  <div
    id="main-header"
    className={
      theme === "TRANSPARENT"
        ? HeaderContainer
        : `${HeaderContainer} ${SolidHeaderContainer}`
    }
  >
    <Row type="flex" align="middle">
      <Col span={4}>
        {theme === "TRANSPARENT" ? (
          <Link to="/">
            <img alt="Logo" width="150" src={LOGO} />
          </Link>
        ) : (
          <Link to="/">
            <img alt="Logo" width="150" src={SOLID_LOGO} />
          </Link>
        )}
      </Col>
      <Col
        className={
          theme === "TRANSPARENT"
            ? SearchContainer
            : `${SearchContainer} ${SolidSearchContainer}`
        }
        span={16}
      >
        <Select
          showSearch
          value={searchValue}
          placeholder={
            <div>
              <Icon type="search" /> Search Trip/Trek
            </div>
          }
          style={{ width: "70%" }}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleSearchValueChange}
          className={`header-search ${
            theme === "TRANSPARENT"
              ? "transparent-header-search"
              : "solid-header-search"
          }`}
          notFoundContent={null}
        >
          {options}
        </Select>
      </Col>
      <Col
        className={
          theme === "TRANSPARENT"
            ? ContactContainer
            : `${ContactContainer} ${SolidContactContainer}`
        }
        span={4}
      >
        <p className={ContactNumber}>+91 9718095544</p>
        <p>himalayanweekenders@gmail.com</p>
      </Col>
    </Row>
    <hr />
    <Row
      type="flex"
      align="center"
      gutter={32}
      className={
        theme === "TRANSPARENT"
          ? HeaderLink
          : `${HeaderLink} ${SolidHeaderLink}`
      }
    >
      <Col>
        <Link to="/">Home</Link>
      </Col>
      <Col>
        <Dropdown overlay={getDropMenu(treks)}>
          <Link to="#">
            Treks <Icon type="down" />
          </Link>
        </Dropdown>
      </Col>
      <Col>
        <Dropdown overlay={getDropMenu(trips)}>
          <Link to="#">
            Trips <Icon type="down"/>
          </Link>
        </Dropdown>
      </Col>
      <Col>
        <Dropdown overlay={getDropMenu(adventureTrips)}>
          <Link to="#">
            Adventure Sports <Icon type="down" />
          </Link>
        </Dropdown>
      </Col>
      <Col>
        <Dropdown overlay={getDropMenu(weekendGetaways)}>
          <Link to="#">
            Weekend Getaways <Icon type="down" />
          </Link>
        </Dropdown>
      </Col>
      <Col>
        <Dropdown overlay={getMoreDropdownDesktop()}>
          <Link to="#">
            More <Icon type="down" />
          </Link>
        </Dropdown>
      </Col>
    </Row>
  </div>
);

const MobileHeader = ({
  theme,
  treks,
  trips,
  adventureTrips,
  weekendGetaways,
  options,
  searchValue,
  handleSearchValueChange,
  handleSearch,
  sideBarVisible,
  showSeacrhBar,
  toggleSidebar,
  toggleSearchbar
}) => (
  <Fragment>
    <Row
      className={
        theme === "TRANSPARENT" ? SidebarTransparentHeader : SidebarSolidHeader
      }
      type="flex"
      justify="space-between"
    >
      {showSeacrhBar ? (
        <Col span={20} style={{paddingTop:'15px'}}>
          <Select
            showSearch
            value={searchValue}
            placeholder={
              <div >
                <Icon type="search" /> Search Trip/Trek
              </div>
            }
            style={{ width: "100%" }}
            size="large"
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleSearchValueChange}
            notFoundContent={null}

          >
            {options}
          </Select>
        </Col>
      ) : (
        <Col onClick={toggleSidebar} >
          {/* {console.log(sideBarVisible)} */}
          <Icon type="menu-fold" theme="outlined" style={{paddingTop: '20px'}}/>
          {theme === "TRANSPARENT" ? (
            <Link to="/" >
              <img alt="Logo" width="150" src={LOGO} style={{position:'absolute', left:'28vw'}}/>
            </Link>
          ) : (
            <Link to="/" >
              <img alt="Logo" width="150" src={SOLID_LOGO} style={{position:'absolute', left:'28vw'}}/>
            </Link>
          )}
        </Col>
      )}
      <Col onClick={toggleSearchbar} span={2}>
        {showSeacrhBar ? (
          <Icon
            style={{
              paddingTop:'15px',
              fontSize: 26,
              lineHeight: 1.5
            }}
            type="close-circle"
            theme="outlined"
          />
        ) : (
          <Icon type="search" theme="outlined" style={{paddingTop:'20px'}}/>
        )}
      </Col>
    </Row>
    <Drawer onClose={toggleSidebar} visible={sideBarVisible} >
      <Menu style={{ border: "none", marginTop:'20px' }} mode="inline">
        <Menu.Item key="home">
          <Link to={`/`} onClick={toggleSidebar}>Home</Link>
        </Menu.Item>
        <SubMenu key="sub1" title="Treks">
          {getMobileDropdown(treks, toggleSidebar)}
        </SubMenu>
        <SubMenu key="sub2" title="Trips">
          {getMobileDropdown(trips, toggleSidebar)}
        </SubMenu>
        <SubMenu key="sub3" title="Adventure Sports">
          {getMobileDropdown(adventureTrips, toggleSidebar)}
        </SubMenu>
        <SubMenu key="sub4" title="Weekend Getaways">
          {getMobileDropdown(weekendGetaways, toggleSidebar)}
        </SubMenu>
        <SubMenu key="sub5" title="More">
          {getMoreDropdownMobile(toggleSidebar)}
        </SubMenu>
      </Menu>
    </Drawer>
  </Fragment>
);
class Header extends Component {
  state = {
    theme: "TRANSPARENT",
    products: [],
    treks: [],
    trips: [],
    adventureTrips: [],
    weekendGetaways: [],
    searchValue: undefined,
    searchResult: [],
    sideBarVisible: false,
    showSeacrhBar: false
  };
  toggleSearchbar = () => {
    this.setState(state => ({
      showSeacrhBar: !state.showSeacrhBar
    }));
  };
  toggleSidebar = () => {
    this.setState(state => ({
      sideBarVisible: !state.sideBarVisible
    }));
  };
  onScroll = () => {
    if (window.scrollY > 50) {
      this.setState({
        theme: "SOLID"
      });
    } else {
      this.setState({
        theme: "TRANSPARENT"
      });
    }
  };
  componentDidMount() {
    if (!this.props.solid) {
      window.addEventListener("scroll", this.onScroll);
    } else {
      this.setState({
        theme: "SOLID"
      });
    }
    const db = getDB();
    db.collection("products")
      .get()
      .then(snapshots => {
        const products = snapshots.docs.map(d => d.data()).filter(d => !d.hide);
        this.setState({
          products,
          treks: products
            .filter(i => i.type === "TREK")
            .sort(function(a, b) {
              return a.name > b.name ? 1 : -1;
            }),
          trips: products
            .filter(i => i.type === "TRIP")
            .sort(function(a, b) {
              return a.name > b.name ? 1 : -1;
            }),
          adventureTrips: products
            .filter(i => i.type === "ADVENTURE_TRIP")
            .sort(function(a, b) {
              return a.name > b.name ? 1 : -1;
            }),
          weekendGetaways: products
            .filter(i => i.type === "WEEKEND_GETAWAYS")
            .sort(function(a, b) {
              return a.name > b.name ? 1 : -1;
            })
        });
      });
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }
  handleSearchValueChange = value => {
    this.setState({
      searchValue: value
    });
  };
  handleSearch = value => {
    const { products } = this.state;
    this.setState({
      searchResult: products.filter(product =>
        product.name.toLowerCase().includes(value.toLowerCase())
      )
    });
  };
  render() {
    const {
      theme,
      treks,
      trips,
      adventureTrips,
      weekendGetaways,
      searchValue,
      searchResult,
      showSeacrhBar,
      sideBarVisible
    } = this.state;
    if (!isMobile()) {
      return (
        <DesktopHeader
          treks={treks}
          theme={theme}
          trips={trips}
          adventureTrips={adventureTrips}
          weekendGetaways={weekendGetaways}
          options={searchResult.map(d => (
            <Option
              onClick={() => (window.location.pathname = "/treks/" + d.slug)}
              key={d.slug}
            >
              {d.name}
            </Option>
          ))}
          handleSearchValueChange={this.handleSearchValueChange}
          handleSearch={this.handleSearch}
          searchValue={searchValue}
        />
      );
    } else {
      return (
        <MobileHeader
          treks={treks}
          trips={trips}
          theme={theme}
          adventureTrips={adventureTrips}
          weekendGetaways={weekendGetaways}
          options={searchResult.map(d => (
            <Option
              onClick={() => (window.location.pathname = "/treks/" + d.slug)}
              key={d.slug}
            >
              {d.name}
            </Option>
          ))}
          handleSearchValueChange={this.handleSearchValueChange}
          handleSearch={this.handleSearch}
          searchValue={searchValue}
          showSeacrhBar={showSeacrhBar}
          sideBarVisible={sideBarVisible}
          toggleSearchbar={this.toggleSearchbar}
          toggleSidebar={this.toggleSidebar}
        />
      );
    }
  }
}

export default Header;
