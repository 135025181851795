import { css } from "emotion";

export const NavigationItem = css`
  padding-bottom: 1%;
  text-align: center;
  text-transform: uppercase;
  color: #8c949d;
  font-size: 14px;
`;

export const NavigationContainer = css`
  padding-top: 8px;
  background: #f3f5f9;
  padding-left: 12vw;
  padding-right: 12vw;
  padding-bottom: 0;

  @media (max-width: 600px) {
    padding-left: 2vw;
    padding-right: 2vw;
    padding-bottom: 8px;
  }
`;

export const SelectedNavigationItem = css`
  border-bottom: 4px solid #00aeef !important;
  color: #00aeef !important;
`;

export const FixedNavigationBar = css`
  position: fixed;
  top: 0;
  width: 100%;
`;

export const BarHeader = css`
  text-align: left;
  font-weight: normal;
  font-size: 24px;
  margin-bottom: 0;

  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

export const BarMeta = css`
  margin-bottom: 15px;
  display: inline-block;
  font-size: 16px;
  letter-spacing:0;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
