import { css } from "emotion";

export const MainContainer = css`
  min-height: 100vh;
  position: relative;
  color: white;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.30));
  ${'' /* background: transparent; */}
`;

export const HompageSlider = css`
  padding: 0 2%;

  hr {
    margin: 0;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  span {
    display: inline-block;
    cursor: pointer;
  }
`;

export const HomepageSliderContainer = css`
  position: absolute;
  bottom: 20px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const ContentContainer = css`
  text-align: center;
  margin-top: 30vh;

  p {
    margin-top: 1%;
    font-weight: lighter;
  }

  button {
    margin-top: 2%;
    border-radius: 20px;
    background-color: #00aeef;
    border-color: #00aeef;
    padding: 6px 20px;
    text-transform: uppercase;
  }
`;


export const ContentSubHeading = css`
  font-size: 18px;
  margin-top: 0;
  font-weight: lighter;
  letter-spacing: 1px;
  font-weight:bold;

  @media (max-width: 600px) {
    font-size: 14px;
    ${'' /* text-align: center;
    width: 90%; */}
  }
`;
export const ContentHeading = css`
  font-size: 56px;
  width: 70%;
  margin: auto;
  text-align: center;
  color: white;
  line-height: 1.35;
  font-family: boldFont, sans-serif;

  @media (max-width: 600px) {
    font-size: 36px;
    width: 80%;
  }
`;

export const HomepageSliderIndex = css`
  margin-right: 8px;
  background-color: rgba(255, 255, 255, 0.14);
  border: solid 1px #ffffff;
  padding: 1px 5px;
`;

export const HompageSliderItem = css`
  padding-top: 2%;
`;
